import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-all-folder",
  templateUrl: "./all-folder.component.html",
  styleUrls: ["./all-folder.component.scss"]
})
export class AllFolderComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  editdata() {
    this.router.navigate(["editcatagory"]);
  }
  back() {
    this.router.navigate(["/addfolder"]);
  }
  addSub() {
    this.router.navigate(["/subcatagory"]);
  }
  addfolder() {
    this.router.navigate(["/managecategories"]);
  }
  deleteData() {}
  addSub1() {
    this.router.navigate(["/addmedia"]);
  }
  drop($event) {}
}
