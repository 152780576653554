import { Component, OnInit, VERSION, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from "../../confirm-dialog/confirm-dialog.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { HttpEvent, HttpEventType, HttpClient, HttpRequest } from "@angular/common/http";
import { FileUploader, FileLikeObject } from "ng2-file-upload";
import { concat } from "rxjs";
import { LoaderService } from "../../services/loader.service";
import * as S3 from 'aws-sdk/clients/s3';
import * as $ from 'jquery';
// import { AuthService } from "angularx-social-login";
// import { GoogleLoginProvider } from "angularx-social-login";
// import axios from "axios";
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import * as AWS from 'aws-sdk/global';

@Component({
  selector: "app-media-manager",
  templateUrl: "./media-manager.component.html",
  styleUrls: ["./media-manager.component.scss"]
})
export class MediaManagerComponent implements OnInit {
  private aki = "QUtJQVNDUkFWS002NEpLVks2T1Y=";
  private sky = "ZnllL2M2cE9pRWJZNXQrREh6cTA2REZNUVNobkRBc1BSSWhmb3FQcA==";
  public nofolder_noimage: boolean;
  public mediaForm: FormGroup;
  public editmediaForm: FormGroup;
  public pushnotificationForm: FormGroup;
  public uploader: FileUploader = new FileUploader({});
  public hasBaseDropZoneOver: boolean = false;
  public submitted = false;
  public categoryForm: FormGroup;
  public empty_parent: boolean;
  public show_parent: boolean;
  public empty_child: boolean;
  public show_subcategory: boolean;
  public show_media: boolean;
  public add_category: boolean;
  public edit_category: boolean;
  public upload_media: boolean;
  public hide_create: boolean = true;
  public play_media: boolean = false;
  public play_media_data: any;
  public uploadedImage: Blob;
  public percentDone: number;
  public uploadSuccess: boolean;
  public size: any;
  public width: number;
  public height: number;
  public controlArray: any;
  uploadfromurlForm: any;

  @ViewChild('coverFilesInput') imgType: ElementRef;
  version = VERSION;

  sub: any;
  public links = [];
  constructor(
    private api: ApiService,
    private store: StorageService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public loader: LoaderService,
    private http: HttpClient,
    private ng2ImgMax: Ng2ImgMaxService,
    public sanitizer: DomSanitizer,
    // private authService: AuthService
  ) {
    this.getcategories(false);
    this.getallcategories();
  }
  changeposition: any = []
  ngOnInit() {
    this.get_app()
    this.categoryForm = this.formBuilder.group({
      category: ["", [Validators.required]],
      parentcategory: [""],
      image: [""],
      description: [""],
      categoryposition: [""],
      categorystatus: [""]
    });
    this.mediaForm = new FormGroup({
      formArrayName: this.formBuilder.array([])
    });
    this.editmediaForm = this.formBuilder.group({
      title: ["", [Validators.required]],
      position: ["", [Validators.required]],
      status: ["publish"],
      description: [""],
      filename: [""],
      pdffile: [""],
      type: [""],
    });
    this.pushnotificationForm = this.formBuilder.group({
      title: ["", [Validators.required, Validators.maxLength(10)]],
      body: ["", [Validators.required, Validators.maxLength(20)]],
      url: [""],
    });
    this.uploadfromurlForm = this.formBuilder.group({
      title: ["", [Validators.required]],
      position: ["0", [Validators.required]],
      status: "publish",
      description: "",
      filename: "",
      pdffile: "",
      extensiontype: "",
      image_obj: "",
      pdf_obj: "",
      type: "standard",
    })
  }


  
  selectall(){
    console.log("Select All")
    console.log($(".example-box tr input:checkbox").not(this).prop('checked', true))
  }

  subapp: any
  get_app(){
    try {
     this.api
       .postmethod("app_cust/app", {
         sessionid: this.store.get_token(),
         appid: this.store.getuserdata().appid.S
       })
       .subscribe((res: any) => {
         if (res.status == "success" && res.code == 200) {
          this.subapp = res.data;
          console.log(this.subapp)
         } else {
           this.api.handle_Error(res);
         }
       });
     } catch (err) {
       this.api.handle_Error(err);
    }
   };

  onChange(evt: any) {
    this.percentDone = 100;
    this.uploadSuccess = true;
    let image: any = evt.target.files[0];

    var filename = image.name;
    var extension = filename.replace(/^.*\./, '');

    if (extension == filename) {
      extension = '';
    } else {
      extension = extension.toLowerCase();
    }

    if ($.inArray(extension, ['jpg', 'png', 'jpeg']) == -1) {
      alert('please valid upload image type supported');
      $(this).val('');
      return false;
    }

    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
      var img = new Image();

      var imgwidth = 0;
      var imgheight = 0;
      var maxwidth = 300;
      var maxheight = 415;

      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        if (this.width <= maxwidth && this.height <= maxheight) {
        } else {
          evt.target.value = ''
          alert('Warning: image dimensions beyound required dimensions 300px X 415px');
        }
      };

      img.src = fr.result as string; // This is the data URL 
    };

    fr.readAsDataURL(image);
    this.imgType.nativeElement.value = "";
  }

  ngOnDestroy() {
    this.changeposition = []
  }
  openpdf(data){
    window.open(data.records.S, '_blank')
  }
  playmedia(data) {
    this.play_media = true;
    this.play_media_data = data
  }
  closemedia() {
    this.play_media = false;
    this.play_media_data = {};
  }
  buildForm(number, extensiontype) {
    this.controlArray = this.mediaForm.get("formArrayName") as FormArray;
    Object.keys(this.selectedfiles).forEach(i => {
      var data = this.selectedfiles[i]
      var extensiontype
      if (
        data.type === "audio/flac" ||
        data.type === "audio/mp4" ||
        data.type === "audio/mp3" ||
        data.type === "audio/mpeg" ||
        data.type === "audio/ogg" ||
        data.type === "audio/aac" ||
        data.type === "audio/wav" ||
        data.type === "audio/x-m4a"
      ) {
        extensiontype = 'audio'
      }
      else if (
        data.type === "video/mp4" ||
        data.type === "video/m4a" ||
        data.type === "video/WEBM" ||
        data.type === "video/OGV" ||
        data.type === "video/MOV" ||
        data.type === "video/quicktime" ||
        data.type === "video/3gpp2" ||
        data.type === "video/FLV") {
        extensiontype = 'video'
      } else if (data.type === 'application/pdf') {
        extensiontype = "pdf"
      }
      this.controlArray.push(
        this.formBuilder.group({
          title: [this.selectedfiles[i].name.split(".")[0], [Validators.required]],
          position: [number++ * number, [Validators.required]],
          status: "publish",
          description: "",
          filename: "",
          pdffile: "",
          extensiontype: extensiontype,
          image_obj: "",
          pdf_obj: "",
          type: "standard",
          progress: 0,
        })
      );
    });
  }

  onImageChangecategory(event) {

    let image: any = event.target.files[0];

    var filename = image.name;
    var extension = filename.replace(/^.*\./, '');

    if (extension == filename) {
      extension = '';
    } else {
      extension = extension.toLowerCase();
    }

    if ($.inArray(extension, ['jpg', 'png', 'jpeg']) == -1) {
      alert('please valid upload image type supported');
      $(this).val('');
      return false;
    }

    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
      var img = new Image();
      var imgwidth = 0;
      var imgheight = 0;
      var maxwidth = 600;
      var maxheight = 300;
      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        if (this.width == maxwidth && this.height == maxheight) {
          this.uploadedImage = event.target.files[0];
        } else {
          event.target.value = ''
          alert('Warning: image required dimensions 600px X 300px');
        }
      };

      img.src = fr.result as string; // This is the data URL 
    };

    fr.readAsDataURL(image);
  }

  onImageChange(event) {

    let image: any = event.target.files[0];

    var filename = image.name;
    var extension = filename.replace(/^.*\./, '');

    if (extension == filename) {
      extension = '';
    } else {
      extension = extension.toLowerCase();
    }

    if ($.inArray(extension, ['jpg', 'png', 'jpeg']) == -1) {
      alert('please valid upload image type supported');
      $(this).val('');
      return false;
    }

    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => { // when file has loaded
      var img = new Image();
      var imgwidth = 0;
      var imgheight = 0;
      var maxwidth = 300;
      var maxheight = 415;
      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        if (this.width <= maxwidth && this.height <= maxheight) {
          this.uploadedImage = event.target.files[0];
        } else {
          event.target.value = ''
          alert('Warning: image dimensions beyound required dimensions 300px X 415px');
        }
      };

      img.src = fr.result as string; // This is the data URL 
    };

    fr.readAsDataURL(image);
    // this.ng2ImgMax.resizeImage(image2, 415, 300).subscribe(
    //   result => {
    //     //this.uploadedImage = result;
    //   },
    //   error => {
    //     console.log('😢 Oh no!', error);
    //   }
    // );
  }

  deletecategoryimg(){
    var ss: any = document.getElementById("upload-media-file")
    ss.value = ""
    this.imageSrc = ""
    this.submitPreview()
  }

  resetBuildForm() {
    var control = <FormArray>this.mediaForm.controls['formArrayName'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }
  m(index) {
    var dd: any = this.mediaForm.controls.formArrayName;
    return dd.controls[index];
  }
  get mm() {
    return this.uploadfromurlForm.controls;
  }
  get p() {
    return this.pushnotificationForm.controls;
  }
  selectedfiles: any;
  progressbars: any;
  changefile(event) {
    this.resetBuildForm();

    this.selectedfiles = [];
    this.progressbars = [];
    for (var j = 0; j < event.target.files.length; j++) {
      this.progressbars.push({progress:0});
    }
    for (var i = 0; i < event.target.files.length; i++) {
      var data = event.target.files[i];
      console.log(data.type)
      var extensiontype
      if (
        data.type === "audio/flac" ||
        data.type === "audio/mp4" ||
        data.type === "audio/mp3" ||
        data.type === "audio/mpeg" ||
        data.type === "audio/ogg" ||
        data.type === "audio/aac" ||
        data.type === "audio/wav" ||
        data.type === "audio/x-m4a"
      ) {
        extensiontype = 'audio'
      }
      else if (
        data.type === "video/mp4" ||
        data.type === "video/m4a" ||
        data.type === "video/WEBM" ||
        data.type === "video/OGV" ||
        data.type === "video/MOV" ||
        data.type === "video/quicktime" ||
        data.type === "video/3gpp2" ||
        data.type === "video/FLV") {
        extensiontype = 'video'
      } else if (data.type === 'application/pdf') {
        extensiontype = "pdf"
      } else {
        var dd: any = document.getElementsByClassName("custom-file-input")[0];
        dd.value = "";
        this.api.handle_Error({ message: "Only audio or video files are acceptable" });
        break;
      }
    }
    this.selectedfiles = event.target.files;
    if (this.lngth == 'category') {
      if (this.categories && this.categories.length) {
        this.buildForm(Math.max.apply(Math, this.categories.map(function (o) { return parseInt(o.categoryposition.N); })) + 1, extensiontype);
      }
      else {
        this.buildForm(Math.floor(Math.random() * 10), extensiontype);
      }
    }
    else if (this.lngth == 'subcategory') {
      if (this.subcategories && this.subcategories.length) {
        this.buildForm(Math.floor(Math.random() * 10), extensiontype);
      }
      else {
        this.buildForm(Math.floor(Math.random() * 10), extensiontype);
      }
    }
  }


  showparent() {
    this.show_parent = true;
    this.show_subcategory = false;
    this.show_media = false;
    this.add_category = false;
    this.edit_category = false;
    this.upload_media = false;
    this.nofolder_noimage = false;
    $(document).ready(function() {
      $("#checkAll").click(function () {
        console.log("click")
        $('input:checkbox').not(this).prop('checked', this.checked);
      });
    });
  }
  showsubcategory() {
    this.show_parent = false;
    this.show_subcategory = true;
    this.show_media = false;
    this.add_category = false;
    this.edit_category = false;
    this.upload_media = false;
    this.nofolder_noimage = false;
    $(document).ready(function() {
      $("#checkAlls").click(function () {
        console.log("click")
        $('input:checkbox').not(this).prop('checked', this.checked);
      });
    });
  }
  bulk_action_data: any = ""

  bulkaction(){
    if(!this.bulk_action_data){
      return alert("Please select action")
    }
    var selectedbulk = []
    var selectedbulkdata = []
    $('input[class="bulk-action"]:checked').each(function() {
      selectedbulk.push(this.value)
   });
   if(selectedbulk && selectedbulk.length){
    selectedbulk.forEach((data, index)=>{
      selectedbulkdata.push(this.categories[parseInt(data)])
      if(index == selectedbulk.length-1){
        this.bulk_action(selectedbulkdata, this.bulk_action_data)
      }
    })
   }
   else{
    alert("Please select lecturers from list")
   }
  }
  bulk_action_datas: any = ""

  bulkactions(){
    if(!this.bulk_action_datas){
      return alert("Please select action")
    }
    var selectedbulk = []
    var selectedbulkdata = []
    $('input[class="bulk-actions"]:checked').each(function() {
      selectedbulk.push(this.value)
   });
   if(selectedbulk && selectedbulk.length){
    selectedbulk.forEach((data, index)=>{
      selectedbulkdata.push(this.subcategorie[parseInt(data)])
      if(index == selectedbulk.length-1){
        this.bulk_action(selectedbulkdata, this.bulk_action_datas)
      }
    })
   }
   else{
    alert("Please select lecturers from list")
   }
  }

  bulk_action(list, action_dta){
    this.goselected = 'go'; 
    try {
      this.api
        .postmethod("user/bulkaction/lecturer", { sessionid: this.store.get_token(), list: list, action: action_dta, appid: this.subapp.Lusername.S })
        .subscribe((res: any) => {
          this.goselected = ''; 
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            setTimeout(()=>{
              if(this.show_parent == true){
                this.bulk_action_data = ""
                this.getcategories(false)
              }
              else if(this.show_subcategory == true){
                this.bulk_action_datas = ""
                this.gotochild(this.selected1, this.selected1.CategoryRange.S, false)
              }
            }, 2000)
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.goselected = ''; 
      this.api.handle_Error(err);
    }
  }
  goselected :any;
  goisActive(item) {
    return this.goselected === item;
  };

  showmedia() {
    this.show_parent = false;
    this.show_subcategory = false;
    // this.show_media = true;
    this.add_category = false;
    this.edit_category = false;
    this.upload_media = false;
    this.nofolder_noimage = false;
  }
  editfolder(data) {
    // this.hide_create_folder = true;
    // this.hide_create_file = true;
    // var dd = {
    //   category: {
    //     S: "Edit Folder"
    //   }
    // };
    // this.links.push(dd);
    // this.show_parent = false;
    // this.show_subcategory = false;
    // this.show_media = false;
    // this.add_category = true;
    this.add_category = true;
    this.edit_category = true;
    // this.upload_media = false;
    this.nofolder_noimage = false;
    this.catid = data.CategoryRange.S;
    this.categoryForm.controls.category.setValue(data.category.S);
    this.categoryForm.controls.categorystatus.setValue(data.categorystatus.S);
    this.categoryForm.controls.description.setValue(data.description.S);
    this.categoryForm.controls.categoryposition.setValue(
      data.categoryposition.N
    );
    if (
      data &&
      data.description &&
      data.description.S &&
      data.description.S != "null"
    ) {
      this.categoryForm.controls.description.setValue(data.description.S);
    } else {
      this.categoryForm.controls.description.setValue("");
    }
    if (
      data &&
      data.parentcategory &&
      data.parentcategory.S &&
      data.parentcategory.S != "null"
    ) {
      this.categoryForm.controls.parentcategory.setValue(data.parentcategory.S);
    } else {
      this.categoryForm.controls.parentcategory.setValue("");
    }
    if (data && data.media && data.media.S && data.media.S != "null") {
      this.imageSrc = data.media.S;
    } else {
      this.imageSrc = "";
    }
    setTimeout(() => {
      var parent: any = document.getElementsByClassName("parent-category")[0];
      parent.disabled = true;
    }, 1000);

    if (this.links.length == 0) {
      this.lngth = "category"
    }
    if (this.links.length > 0) {
      this.lngth = "subcategory"
    }
  }
  lngth: any
  addfolder() {
    this.imageSrc = ""
    this.add_category = true;
    this.edit_category = false;
    this.nofolder_noimage = false;
    if (this.links.length == 0) {
      this.lngth = "category"
      if (this.categories && this.categories.length) {
        // this.categoryForm.controls.categoryposition.setValue(
        //   Math.max.apply(Math, this.categories.map(function (o) { return parseInt(o.categoryposition.N); })) + 1
        // );
        this.categoryForm.controls.categoryposition.setValue(0);
      }
    }
    if (this.links.length > 0) {
      this.lngth = "subcategory"
      if (this.subcategories && this.subcategories.length) {
        // this.categoryForm.controls.categoryposition.setValue(
        //   Math.max.apply(Math, this.subcategories.map(function (o) { return parseInt(o.categoryposition.N); })) + 1
        // );
        this.categoryForm.controls.categoryposition.setValue(0);
      }
      else {
        // this.categoryForm.controls.categoryposition.setValue(1);
        this.categoryForm.controls.categoryposition.setValue(0);
      }
      this.categoryForm.controls.parentcategory.setValue(
        this.links[this.links.length - 1].CategoryRange.S
      );
    }
    this.categoryForm.controls.categorystatus.setValue('publish');
    setTimeout(() => {
      var parent: any = document.getElementsByClassName("parent-category")[0];
      parent.disabled = true;
    }, 1000);
  }
  addmedia() {
    this.selectedfiles = []
    this.upload_media = true;
    this.nofolder_noimage = false;

    if (this.links.length == 0) {
      this.lngth = "category"
    }
    if (this.links.length > 0) {
      this.lngth = "subcategory"
    }
  }
  addUpload() {
    this.router.navigate(["/managemedia"]);
  }
  sortvalues = ""
  sortvaluep = ""
  sortchanged(event){
    if(this.show_parent){
      if(event == 'az'){
        this.categories.sort((a,b)=>a.sortname.localeCompare(b.sortname));
        this.update_position1(this.categories[0].CategoryPK.S, this.categories[0].parentcategory.S, this.categories)
      }
      else if(event == 'za'){
        this.categories.sort((a,b)=>b.sortname.localeCompare(a.sortname));
        this.update_position1(this.categories[0].CategoryPK.S, this.categories[0].parentcategory.S, this.categories)
      }
      else if(event == 'on'){
        this.categories.sort(function(a: any, b: any){return parseInt(a.time_stamp.N)-parseInt(b.time_stamp.N)})
        this.update_position1(this.categories[0].CategoryPK.S, this.categories[0].parentcategory.S, this.categories)
      }
      else if(event == 'no'){
        this.categories.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
        this.update_position1(this.categories[0].CategoryPK.S, this.categories[0].parentcategory.S, this.categories)
      }
      else{
        this.categories.sort(function(a: any, b: any){return parseInt(a.categoryposition.N)-parseInt(b.categoryposition.N)})
        this.update_position1(this.categories[0].CategoryPK.S, this.categories[0].parentcategory.S, this.categories)
      }
    }
    else if(this.show_subcategory){
      if(event == 'az'){
        this.subcategorie.sort((a,b)=>a.sortname.localeCompare(b.sortname));
        this.update_position1(this.subcategorie[0].CategoryPK.S, this.subcategorie[0].parentcategory.S, this.subcategorie)
      }
      else if(event == 'za'){
        this.subcategorie.sort((a,b)=>b.sortname.localeCompare(a.sortname));
        this.update_position1(this.subcategorie[0].CategoryPK.S, this.subcategorie[0].parentcategory.S, this.subcategorie)
      }
      else if(event == 'on'){
        this.subcategorie.sort(function(a: any, b: any){return parseInt(a.time_stamp.N)-parseInt(b.time_stamp.N)})
        this.update_position1(this.subcategorie[0].CategoryPK.S, this.subcategorie[0].parentcategory.S, this.subcategorie)
      }
      else if(event == 'no'){
        this.subcategorie.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
        this.update_position1(this.subcategorie[0].CategoryPK.S, this.subcategorie[0].parentcategory.S, this.subcategorie)
      }
      else{
        this.subcategorie.sort(function(a: any, b: any){return parseInt(a.categoryposition.N)-parseInt(b.categoryposition.N)})
        this.update_position1(this.subcategorie[0].CategoryPK.S, this.subcategorie[0].parentcategory.S, this.subcategorie)
      }
    }
    else{}
  }

  allcategories: any;
  categories: any;
  subcategorie: any;
  subcategories: any;
  subpagenumbers: any = ''
  subpagenumber: any = ''
  medias: any;
  fetchingallfolder = false
  getallcategories() {
    this.fetchingallfolder = true
    try {
      this.api
        .postmethod("user/all/category", {
          sessionid: this.store.get_token(),
          userid: this.store.getuserdata().Lusername.S,
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          this.fetchingallfolder = false
          if (res.status == "success" && res.code == 200) {
            this.allcategories = res.data.Items;
            if (this.allcategories.length > 0) {
              this.nofolder_noimage = false;
            } else {
              this.nofolder_noimage = true;
            }
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.fetchingallfolder = false
      this.api.handle_Error(err);
    }
  }

  lecturers = [];
  lecturer_list = [];
  pagenumbers: any = ''
  pagenumber: any = ''

  getcategories(changedposition) {
    this.upload_media = false
    if(changedposition && this.categories.length){
      try {
        this.api
          .postmethod("user/reorder/categoriess", {
            sessionid: this.store.get_token(),
            appid: this.store.getuserdata().appid.S,
            pcatid: this.categories[0].parentcategory.S,
            list: this.categories
          })
          .subscribe((res: any) => {
            this.getcategoriess()
          });
      } catch (err) {
        this.getcategoriess()
      }
    }
    else{
      this.getcategoriess()
    }
  }

  doubleClickselect: any
  doubleClick(event, cat1, cat2, cat3, cat4, cat5, cat6, cat7){
    if(this.move_media_item && this.move_media_item.CategoryRange && this.move_media_item.CategoryRange.S && event && event.CategoryRange && this.move_media_item.CategoryRange.S == event.CategoryRange.S){
      return
    }
    if(
      cat1 && 
      cat1.CategoryRange &&
      cat1.CategoryRange.S &&
      cat1.CategoryRange.S == this.move_media_item.CategoryRange.S
    ){
      return
    }
    if(
      cat2 && 
      cat2.CategoryRange &&
      cat2.CategoryRange.S &&
      cat2.CategoryRange.S == this.move_media_item.CategoryRange.S
    ){
      return
    }
    if(
      cat3 && 
      cat3.CategoryRange &&
      cat3.CategoryRange.S &&
      cat3.CategoryRange.S == this.move_media_item.CategoryRange.S
    ){
      return
    }
    if(
      cat4 && 
      cat4.CategoryRange &&
      cat4.CategoryRange.S &&
      cat4.CategoryRange.S == this.move_media_item.CategoryRange.S
    ){
      return
    }
    if(
      cat5 && 
      cat5.CategoryRange &&
      cat5.CategoryRange.S &&
      cat5.CategoryRange.S == this.move_media_item.CategoryRange.S
    ){
      return
    }
    if(
      cat6 && 
      cat6.CategoryRange &&
      cat6.CategoryRange.S &&
      cat6.CategoryRange.S == this.move_media_item.CategoryRange.S
    ){
      return
    }
    if(
      cat7 && 
      cat7.CategoryRange &&
      cat7.CategoryRange.S &&
      cat7.CategoryRange.S == this.move_media_item.CategoryRange.S
    ){
      return
    }
    this.doubleClickselect = event
  }

  reassignfolder(){
    if(this.doubleClickselect && this.doubleClickselect.CategoryPK && this.doubleClickselect.CategoryPK.S){

    }
    else{
      this.api.popover("Please double click to select destination folder.");
      return
    }
    try {
      this.api
        .postmethod("user/reassign/folder", {
          sessionid: this.store.get_token(),
          userid: this.store.getuserdata().Lusername.S,
          appid: this.store.getuserdata().appid.S, 
          folder: this.doubleClickselect,
          media: this.move_media_item
        })
        .subscribe((res: any) => {
          var tis = this;
          if (res.status == "success" && res.code == 200 ) {
            this.doubleClickselect = {}
            this.move_media_item = {}
            this.move_media = false
            if(this.show_parent){
              this.getcategoriess()
            }
            else if(this.show_subcategory){
              this.gotochild(this.selected1, this.selected1.CategoryRange.S, false)
            }
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

// Refresh check
  regetcategories(){
    try {
      this.api
        .postmethod("user/parent/category", {
          sessionid: this.store.get_token(),
          userid: this.store.getuserdata().Lusername.S,
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          var tis = this;
          if (res.status == "success" && res.code == 200 ) {
            if(this.links.length == 0){
              this.categories = res.data.Items;
              tis.categories.forEach((data, index) => {
                if(data.upload_type.S == "category"){
                  tis.categories[index].sortname = tis.categories[index].category.S
                }
                else if(data.upload_type.S == "media"){
                  if(tis.categories[index].duration && tis.categories[index].duration.S){
                    tis.categories[index].durations = tis.toTimeString(parseInt(tis.categories[index].duration.S))
                  }
                  tis.categories[index].sortname = tis.categories[index].title.S
                }
              })
              if(this.sortvaluep){
                if(this.sortvaluep == "az"){
                  this.categories.sort((a,b)=>a.sortname.localeCompare(b.sortname));
                }
                else if(this.sortvaluep == "za"){
                  this.categories.sort((a,b)=>b.sortname.localeCompare(a.sortname));
                }
                else if(this.sortvaluep == "on"){     
                  this.categories.sort(function(a: any, b: any){return parseInt(a.time_stamp.N)-parseInt(b.time_stamp.N)})

                }
                else if(this.sortvaluep == "no"){
                  this.categories.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
                }
              }
              else{
                this.categories.sort(function(a: any, b: any){return parseInt(a.categoryposition.N)-parseInt(b.categoryposition.N)})
              }
              this.getcategories(true)
              if (this.categories && this.categories.length == 0) {
                this.empty_parent = true;
              } else if (this.categories && this.categories.length > 0) {
                this.empty_parent = false;
                if (!this.upload_media) {
                  this.hide_create = true;
                  this.showparent();
                }
              }
              console.log(tis.categories)
            }
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }


  getcategoriess(){
    try {
      this.api
        .postmethod("user/parent/category", {
          sessionid: this.store.get_token(),
          userid: this.store.getuserdata().Lusername.S,
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          var tis = this;
          if (res.status == "success" && res.code == 200) {
            if(tis.links.length == 0){
              tis.categories = res.data.Items;
              tis.categories.forEach((data, index) => {
                if(data.upload_type.S == "category"){
                  tis.categories[index].sortname = tis.categories[index].category.S
                }
                else if(data.upload_type.S == "media"){
                  if(tis.categories[index].duration && tis.categories[index].duration.S){
                    tis.categories[index].durations = tis.toTimeString(parseInt(tis.categories[index].duration.S))
                  }
                  tis.categories[index].sortname = tis.categories[index].title.S
                }
              })
              // if(tis.sortvaluep){
              //   console.log(tis.sortvaluep)
              //   if(tis.sortvaluep == "az"){
              //     tis.categories.sort((a,b)=>a.sortname.localeCompare(b.sortname));
              //   }
              //   else if(tis.sortvaluep == "za"){
              //     tis.categories.sort((a,b)=>b.sortname.localeCompare(a.sortname));
              //   }
              // }
              // else{
                tis.categories.sort(function(a: any, b: any){return parseInt(a.categoryposition.N)-parseInt(b.categoryposition.N)})
              // }
              this.lecturer_list = []
              this.lecturers = res.data.Items
              this.listqty = 10
              this.selected = 1
              // this.lecturers.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
              // this.lecturers.sort(function(a: any, b: any){return parseInt(a.categoryposition.N)-parseInt(b.categoryposition.N)})
              // this.lecturers.forEach((data, index) => {
              //   if (index < 10) {
              //     this.lecturer_list.push(data)
              //   }
              //   if(index == 9){
              //     this.lecturer_list.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
              //   }
              // })
              this.pagenumbers = [];
              this.pagenumber = [];
              this.pagenumbers = this.api.getpageslength(
                this.lecturers.length
              );
              this.pagenumber = this.api.getpagelength(
                this.lecturers.length
              );
  
              if (this.categories && this.categories.length == 0) {
                this.empty_parent = true;
                // this.hide_create_file = false;
                // this.hide_create_folder = false;
                // try {
                //   this.api
                //     .postmethod("user/child/media", {
                //       sessionid: this.store.get_token(),
                //       child: 'null',
                //       appid: this.store.getuserdata().appid.S
                //     })
                //     .subscribe((res: any) => {
                //       var tis = this;
                //       if (res.status == "success" && res.code == 200) {
                //         if (res.data.Items && res.data.Items.length) {
                //           this.hide_create_file = false;
                //           this.hide_create_folder = true;
                //           this.medias = res.data.Items;
                //           this.showmedia();
                //         } else {
                //           this.hide_create_file = false;
                //           this.hide_create_folder = false;
                //           this.nofolder_noimage = true;
                //         }
                //       } else {
                //         this.api.handle_Error(res);
                //       }
                //     });
                // } catch (err) {
                //   this.api.handle_Error(err);
                // }
              } else if (this.categories && this.categories.length > 0) {
                this.empty_parent = false;
  
                // this.hide_create_file = true;
                // this.hide_create_folder = false;
                if (!this.upload_media) {
                  this.hide_create = true;
                  this.showparent();
                }
                // this.addmedia();
              }
              console.log(this.categories)
            }
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  toTimeString(seconds) {
    return (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
  }
  backlink(i) {
    this.sortvalues = ""
    this.sortvaluep = ""
    if (i > -1) {
      if (this.links && this.links[i] && this.links[i].CategoryRange && this.links[i].CategoryRange.S) {
        // this.gotochild(this.links[i], this.links[i].CategoryRange.S, true);
        this.regotochild(this.links[i], this.links[i].CategoryRange.S);
        this.links = this.links.slice(0, i + 1);
        this.hide_create = true
      }
      else {
        // this.getcategories(true);
        this.regetcategories()
        this.links = [];
        this.hide_create = true
        this.upload_media = false
      }
    } else {
      // this.getcategories(true);
      this.regetcategories()
      this.links = [];
      this.hide_create = true
      this.upload_media = false
    }
  }
  selected1: any
  gotochild(category, child, reorderlist) {
    this.selected = category
    this.selected1 = category
    if(reorderlist && this.subcategorie.length){
      try {
        this.api
          .postmethod("user/reorder/categoriess", {
            sessionid: this.store.get_token(),
            appid: this.store.getuserdata().appid.S,
            pcatid: this.subcategorie[0].parentcategory.S,
            list: this.subcategorie
          })
          .subscribe((res: any) => {
            this.gotochildd(category, child)
          });
      } catch (err) {
        this.getcategoriess()
      }
    }
    else{
      this.sortvalues = ""
      this.gotochildd(category, child)
    }
  }
  regotochild(category, child){
    try {
      this.api
        .postmethod("user/child/category", {
          sessionid: this.store.get_token(),
          child: child,
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          var tis = this;
          if (res.status == "success" && res.code == 200) {
            if (res.data.Items && res.data.Items.length > 0) {
              this.selected = ""
              if (category != "null" && child) {
                if (
                  this.links.findIndex(
                    e => e.category.S == category.category.S
                  ) > -1
                ) {
                } else {
                  this.links.push(category);
                }
                this.subcategorie = res.data.Items;
                this.subcategorie.forEach((data, index) => {
                  if(data.upload_type.S == "category"){
                    tis.subcategorie[index].sortname = this.subcategorie[index].category.S
                  }
                  else if(data.upload_type.S == "media"){
                    if(tis.subcategorie[index].duration && tis.subcategorie[index].duration.S){
                      tis.subcategorie[index].durations = tis.toTimeString(parseInt(tis.subcategorie[index].duration.S))
                    }
                    tis.subcategorie[index].sortname = this.subcategorie[index].title.S
                  }
                })
                if(this.sortvalues){
                  if(this.sortvalues == "az"){
                    this.subcategorie.sort((a,b)=>a.sortname.localeCompare(b.sortname));
                  }
                  else if(this.sortvalues == "za"){
                    this.subcategorie.sort((a,b)=>b.sortname.localeCompare(a.sortname));
                  }
                  else if(this.sortvaluep == "on"){     
                    this.subcategorie.sort(function(a: any, b: any){return parseInt(a.time_stamp.N)-parseInt(b.time_stamp.N)})
                  }
                  else if(this.sortvaluep == "no"){
                    this.subcategorie.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
                  }
                }
                else{
                  this.subcategorie.sort(function(a: any, b: any){return parseInt(a.categoryposition.N)-parseInt(b.categoryposition.N)})
                }
                this.empty_child = false;
                this.showsubcategory();
                this.subcategories = []
                this.sublistqty = 10
                this.selected = 1
                this.gotochild(category, child, true);
              }

              this.subpagenumbers = [];
              this.subpagenumber = [];
              this.subpagenumbers = this.api.getpageslength(
                this.subcategorie.length
              );
              this.subpagenumber = this.api.getpagelength(
                this.subcategorie.length
              );
            } else if (res.data.Items && res.data.Items.length == 0) {
              this.showmedia();
              // this.hide_create_file = false;
              // this.hide_create_folder = false;
              this.nofolder_noimage = true;
              if (
                this.links.findIndex(
                  e => e.category.S == category.category.S
                ) > -1
              ) {
              } else {
                this.links.push(category);
              }
            }
            else {
              this.selected = ""
            }
          } else {
            this.selected = ""
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.selected = ""
      this.api.handle_Error(err);
    }
  }
  gotochildd(category, child){
    try {
      this.api
        .postmethod("user/child/category", {
          sessionid: this.store.get_token(),
          child: child,
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          var tis = this;
          if (res.status == "success" && res.code == 200) {
            if (res.data.Items && res.data.Items.length > 0) {
              this.selected = ""
              // this.hide_create_file = true;
              // this.hide_create_folder = false;
              if (category != "null" && child) {
                if (
                  this.links.findIndex(
                    e => e.category.S == category.category.S
                  ) > -1
                ) {
                } else {
                  this.links.push(category);
                }
                this.subcategorie = res.data.Items;
                this.subcategorie.sort(function(a: any, b: any){return parseInt(a.categoryposition.N)-parseInt(b.categoryposition.N)})
                this.empty_child = false;
                this.showsubcategory();
                this.subcategories = []
                this.sublistqty = 10
                this.selected = 1
                // this.lecturers.sort(function(a: any, b: any){return parseInt(a.categoryposition.N)-parseInt(b.categoryposition.N)})
                this.subcategorie.forEach((data, index) => {
                  if (index < 10) {
                    tis.subcategories.push(data)
                  }
                  if(index == 9){
                    // this.subcategories.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
                  }
                  if(data.upload_type.S == "category"){
                    tis.subcategorie[index].sortname = this.subcategorie[index].category.S
                  }
                  else if(data.upload_type.S == "media"){
                    if(tis.subcategorie[index].duration && tis.subcategorie[index].duration.S){
                      tis.subcategorie[index].durations = tis.toTimeString(parseInt(tis.subcategorie[index].duration.S))
                    }
                    tis.subcategorie[index].sortname = this.subcategorie[index].title.S
                  }
                })
              }

              this.subpagenumbers = [];
              this.subpagenumber = [];
              this.subpagenumbers = this.api.getpageslength(
                this.subcategorie.length
              );
              this.subpagenumber = this.api.getpagelength(
                this.subcategorie.length
              );
            } else if (res.data.Items && res.data.Items.length == 0) {
              this.showmedia();
              // this.hide_create_file = false;
              // this.hide_create_folder = false;
              this.nofolder_noimage = true;
              if (
                this.links.findIndex(
                  e => e.category.S == category.category.S
                ) > -1
              ) {
              } else {
                this.links.push(category);
              }
              //   if (category != "null" && child) {
              //     if (
              //       this.links.findIndex(
              //         e => e.category.S == category.category.S
              //       ) > -1
              //     ) {
              //     } else {
              //       this.links.push(category);
              //     }
              //     this.empty_child = true;
              //     this.showsubcategory();
              //   }
              //   try {
              //     this.api
              //       .postmethod("user/child/media", {
              //         sessionid: this.store.get_token(),
              //         child: child,
              //         appid: this.store.getuserdata().appid.S
              //       })
              //       .subscribe((res: any) => {
              //         this.selected = ""
              //         var tis = this;
              //         if (res.status == "success" && res.code == 200) {
              //           if (res.data.Items && res.data.Items.length) {
              //             this.hide_create_file = false;
              //             this.hide_create_folder = true;
              //             if (
              //               this.links.findIndex(
              //                 e => e.category.S == category.category.S
              //               ) > -1
              //             ) {
              //             } else {
              //               this.links.push(category);
              //             }
              //             this.medias = res.data.Items;
              //             console.log(this.medias);
              //             this.showmedia();
              //           } else {
              //             this.hide_create_file = false;
              //             this.hide_create_folder = false;
              //             this.nofolder_noimage = true;
              //           }
              //         } else {
              //           this.api.handle_Error(res);
              //         }
              //       });
              //   } catch (err) {
              //     this.selected = ""
              //     this.api.handle_Error(err);
              //   }
            }
            else {
              this.selected = ""
            }
          } else {
            this.selected = ""
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.selected = ""
      this.api.handle_Error(err);
    }
  }

  isActivechangeposition(item) {
    return this.changeposition.includes(item);
  };
  drop(event: CdkDragDrop<string[]>) {
    // moveItemInArray(this.lecturer_list, event.previousIndex, event.currentIndex);
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    this.update_position1(this.categories[0].CategoryPK.S, this.categories[0].parentcategory.S, this.categories)
    // if (event.currentIndex == 0) {
    //   this.changeposition.push(this.categories[event.currentIndex].CategoryRange.S)
    //   for (var c = 0; c <= event.previousIndex; c++) {
    //     if (c == 0) {
    //       this.update_position(this.categories[event.currentIndex].CategoryPK.S, this.categories[event.currentIndex].CategoryRange.S, c + 1)
    //     }
    //     if (c > 0) {
    //       this.update_position(this.categories[event.currentIndex + c].CategoryPK.S, this.categories[event.currentIndex + c].CategoryRange.S, c + c)
    //     }
    //   }
    // }
    // if (event.currentIndex > 0) {
    //   if (event.previousIndex < event.currentIndex) {
    //     this.changeposition.push(this.categories[event.currentIndex].CategoryRange.S)
    //     for (var c = event.previousIndex; c <= event.currentIndex; c++) {
    //       this.update_position(this.categories[c].CategoryPK.S, this.categories[c].CategoryRange.S, c + 1)
    //     }
    //   }
    //   if (event.previousIndex > event.currentIndex) {
    //     this.changeposition.push(this.categories[event.currentIndex].CategoryRange.S)
    //     for (var c = event.currentIndex; c <= event.previousIndex; c++) {
    //       this.update_position(this.categories[c].CategoryPK.S, this.categories[c].CategoryRange.S, c + 1)
    //     }
    //   }
    // }
  }

  dropSubcategory(event: CdkDragDrop<string[]>) {
    // moveItemInArray(this.subcategories, event.previousIndex, event.currentIndex);
    moveItemInArray(this.subcategorie, event.previousIndex, event.currentIndex);
    this.update_position1(this.subcategorie[0].CategoryPK.S, this.subcategorie[0].parentcategory.S, this.subcategorie)
    // if (event.currentIndex == 0) {
    //   this.changeposition.push(this.subcategorie[event.currentIndex].CategoryRange.S)
    //   for (var c = 0; c <= event.previousIndex; c++) {
    //     if (c == 0) {
    //       this.update_position(this.subcategorie[event.currentIndex].CategoryPK.S, this.subcategorie[event.currentIndex].CategoryRange.S, c + 1)
    //     }
    //     if (c > 0) {
    //       this.update_position(this.subcategorie[event.currentIndex + c].CategoryPK.S, this.subcategorie[event.currentIndex + c].CategoryRange.S, c + c)
    //     }
    //   }
    // }
    // if (event.currentIndex > 0) {
    //   if (event.previousIndex < event.currentIndex) {
    //     this.changeposition.push(this.subcategorie[event.currentIndex].CategoryRange.S)
    //     for (var c = event.previousIndex; c <= event.currentIndex; c++) {
    //       this.update_position(this.subcategorie[c].CategoryPK.S, this.subcategorie[c].CategoryRange.S, c + 1)
    //     }
    //   }
    //   if (event.previousIndex > event.currentIndex) {
    //     this.changeposition.push(this.subcategorie[event.currentIndex].CategoryRange.S)
    //     for (var c = event.currentIndex; c <= event.previousIndex; c++) {
    //       this.update_position(this.subcategorie[c].CategoryPK.S, this.subcategorie[c].CategoryRange.S, c + 1)
    //     }
    //   }
    // }
  }

  dropMedia(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.medias, event.previousIndex, event.currentIndex);
  }

  addnew() {
    this.router.navigate(["/managecategories"]);
  }
  edit_upload(data) {
    // this.hide_create = false
    if (data.upload_type.S == 'media') {
      this.editmedia(data);
    }
    else if (data.upload_type.S == 'category') {
      this.editfolder(data);
    }
  }
  editdata(data) {
    // this.router.navigate(["editcatagory"]);
    this.editfolder(data);
  }
  back() {
    this.router.navigate(["/addfolder"]);
  }
  addSub() {
    this.router.navigate(["/subcatagory"]);
  }
  delete_upload(data) {
    this.confirmDialog(data.CategoryPK.S, data.CategoryRange.S, data.upload_type.S);
  }
  addSub1() {
    this.router.navigate(["/addmedia"]);
  }

  ///////////////////////////////////////
  // Submit Category
  ///////////////////////////////////////

  imageSrc: any;
  readURL(event: Event): void {
    var ss: any = event.target;
    if (ss.files && ss.files[0]) {
      const file = ss.files[0];

      const reader = new FileReader();
      reader.onload = e => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
    }
  }
  uploadimage(file) {
  }
  get f() {
    return this.categoryForm.controls;
  }
  resetPreview(data) { }

  ngSubmit() {
    this.router.navigate(["/allfolder"]);
  }

  wresetPreview() {
    this.router.navigate(["/addfolder"]);
  }
  submitPreview() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return;
    }
    this.hide_create = true
    this.updateposition()
    var appid;
    if (this.store.getuserdata().appid && this.store.getuserdata().appid.S) {
      appid = this.store.getuserdata().appid.S;
    }
    if (this.edit_category) {
      var ss: any = document.getElementById("upload-media-file");

      if (ss && ss.value) {
        var files: any = document.getElementById("upload-media-file");
        var file = files.files[0];
        if (file == null) {
          return alert("No file selected.");
        }
        this.api
          .uploadimage("image", file, "image-upload", this.subapp.Lusername.S)
          .subscribe((event: HttpEvent<any>) => {
            var dd: any = event;
            if (dd && dd.body && dd.body.imageUrl) {
              this.editcategory(appid, dd.body.imageUrl);
            }
          });
      } else {
        if(this.imageSrc && this.imageSrc != "null"){
          this.editcategory(appid, this.imageSrc);
        }
        else{
          this.editcategory(appid, "");
        }
      }
    } else {
      var ss: any = document.getElementById("upload-media-file");
      if (ss && ss.value) {
        var files: any = document.getElementById("upload-media-file");
        var file = files.files[0];
        file = this.uploadedImage;
        if (file == null) {
          return alert("No file selected.");
        }
        this.api
          .uploadimage("image", file, "image-upload", this.subapp.Lusername.S)
          .subscribe((event: HttpEvent<any>) => {
            var dd: any = event;
            if (dd && dd.body && dd.body.imageUrl) {
              this.submit(appid, dd.body.imageUrl);
            }
          });
      } else {
        this.submit(appid, "");
      }
    }
  }
  catid: any;
  updateposition() {
    if (this.categoryForm.value.categoryposition && this.lngth == 'category' && this.categories && this.categories.length) {
      if (this.categories.findIndex(e => e.categoryposition.N == this.categoryForm.value.categoryposition) > -1) {
        // this.categoryForm.controls.categoryposition.setValue('');
        // this.api.popover("Position already taken");
        // return
        this.categoryForm.controls.categoryposition.setValue(
          Math.max.apply(Math, this.categories.map(function (o) { return parseInt(o.categoryposition.N); })) + 100
        );
      }
    }
    else if (this.categoryForm.value.categoryposition && this.lngth == 'subcategory' && this.subcategories && this.subcategories.length) {
      if (this.subcategories.findIndex(e => e.categoryposition.N == this.categoryForm.value.categoryposition) > -1) {
        // this.categoryForm.controls.categoryposition.setValue('');
        // this.api.popover("Position already taken");
        // return
        this.categoryForm.controls.categoryposition.setValue(
          Math.max.apply(Math, this.subcategories.map(function (o) { return parseInt(o.categoryposition.N); })) + 100
        );
      }
    }
  }
  updatingposition1: boolean = false;
  update_position1(appid, pcatid, list) {
    this.updatingposition1 = true
    try {
      this.api
        .postmethod("user/reorder/categoriess", {
          sessionid: this.store.get_token(),
          appid: appid,
          pcatid: pcatid,
          list: list
        })
        .subscribe((res: any) => {
          this.updatingposition1 = false
          if (res.status == "success" && res.code == 200) {
            if (this.links.length > 0) {
              // this.backlink(this.links.length - 1);
              this.gotochild(this.links[this.links.length - 1], this.links[this.links.length - 1].CategoryRange.S, true);
            } else {
              this.getcategoriess()
            }
          } else {
            this.api.handle_Error({code: 400, message: "Not able to reorder due to the internet speed, Please reorder the file again to fix this position"});
          }
        });
    } catch (err) {
      this.updatingposition1 = false
      this.api.handle_Error({code: 400, message: "Not able to reorder due to the internet speed, Please reorder the file again to fix this position"});
    }
  }
  update_position(appid, catid, position) {
    // try {
    //   this.api
    //     .postmethod("user/reorder/user", {
    //       sessionid: this.store.get_token(),
    //       appid: appid,
    //       catid: catid,
    //       categoryposition: position
    //     })
    //     .subscribe((res: any) => {
    //       // this.categoryForm.reset()
    //       if (res.status == "success" && res.code == 200) {
    //         this.changeposition.splice(this.changeposition.findIndex(e => e == catid), 1)
    //       } else {
    //         this.api.handle_Error("Not able to reorder due to the internet speed, Please reorder the file again to fix this position");
    //       }
    //     });
    // } catch (err) {
    //   this.api.handle_Error("Not able to reorder due to the internet speed, Please reorder the file again to fix this position");
    // }
  }
  editcategory(appid, imageurl) {
    this.updateposition()
    try {
      this.api
        .postmethod("user/edit/category", {
          sessionid: this.store.get_token(),
          appid: appid,
          catid: this.catid,
          category: this.categoryForm.value.category,
          parentcategory: this.categoryForm.value.parentcategory,
          categorystatus: this.categoryForm.value.categorystatus,
          categoryposition: this.categoryForm.value.categoryposition,
          description: this.categoryForm.value.description,
          medias: imageurl
        })
        .subscribe((res: any) => {
          this.categoryForm.reset()
          if (res.status == "success" && res.code == 200) {
            this.getallcategories();
            this.api.popover(res.message);
            this.add_category = false
            this.backlink(this.links.length - 1);
            // this.router.navigate(["/allfolder"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  deletecategory(appid, catid) {
    try {
      this.api
        .postmethod("user/delete/category", {
          sessionid: this.store.get_token(),
          appid: appid,
          catid: catid
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.backlink(this.links.length - 1);
            // this.router.navigate(["/allfolder"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  deletemedia(appid, catid) {
    try {
      this.api
        .postmethod("user/delete/media", {
          sessionid: this.store.get_token(),
          appid: appid,
          catid: catid
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.backlink(this.links.length - 1);
            // this.router.navigate(["/allfolder"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  submit(appid, imageurl) {
    try {
      this.api
        .postmethod("user/create/category", {
          sessionid: this.store.get_token(),
          appid: appid,
          category: this.categoryForm.value.category,
          parentcategory: this.categoryForm.value.parentcategory,
          categorystatus: this.categoryForm.value.categorystatus,
          categoryposition: this.categoryForm.value.categoryposition,
          description: this.categoryForm.value.description,
          medias: imageurl,
          userid: this.store.getuserdata().Lusername.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.categoryForm.reset()
            this.getallcategories();
            this.api.popover(res.message);
            this.add_category = false
            this.uploadedImage = new Blob()
            if (this.links.length > 0) {
              this.backlink(this.links.length - 1);
              // this.gotochild(this.links[this.links.length - 1], this.links[this.links.length - 1].CategoryRange.S, true);
            } else {
              this.links = []
              this.getcategories(true);
            }
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  ///////////////////////////////////////
  // Edit Media
  ///////////////////////////////////////
  edit_media: boolean = false
  edit_media_data: any = {}
  editmedia(media) {
    this.edit_media = true
    this.edit_media_data = media
    if (media.title.S && media.title.S != 'null') {
      this.editmediaForm.controls.title.setValue(
        media.title.S
      );
    }
    if (media.categorystatus && media.categorystatus.S && media.categorystatus.S != 'null') {
      this.editmediaForm.controls.status.setValue(
        media.categorystatus.S
      );
    }
    if (media.description.S && media.description.S != 'null') {
      this.editmediaForm.controls.description.setValue(
        media.description.S
      );
    }
    if (media.media.S && media.media.S != 'null') {
      this.editimageSrc = media.media.S
    }
    if (media.pdf_file && media.pdf_file.S && media.pdf_file.S != 'null') {
      this.editpdfSrc.push(media.pdf_file.S)
    }
    if (media.pdf_file && media.pdf_file.SS && media.pdf_file.SS.length) {
      this.editpdfSrc = media.pdf_file.SS
    }
    if (media.categoryposition.N && media.categoryposition.N != 'null') {
      this.editmediaForm.controls.position.setValue(
        media.categoryposition.N
      );
    }
    if (media.content_type && media.content_type.S && media.content_type.S != 'null') {
      this.editmediaForm.controls.type.setValue(
        media.content_type.S
      );
    }
    else{
      this.editmediaForm.controls.type.setValue(
        "standard"
      );
    }
  }
  backeditmedia() {
    this.hide_create = true
    this.edit_media = false
    this.edit_media_data = {}
    this.editpdfSrc = []
    this.editimageSrc = ""
  }

  editimageSrc: any = "";
  editpdfSrc: any = [];
  editpdfSrcs: any;
  readeditURL(event: Event): void {
    var ss: any = event.target;
    if (ss.files && ss.files[0]) {
      const file = ss.files[0];
      const reader = new FileReader();
      reader.onload = e => (this.editimageSrc = reader.result);
      reader.readAsDataURL(file);
    }
  }
  readeditpdfURL(event): void {
    this.editpdfSrcs = []
    // var ss: any = event.target;
    // if (ss.files && ss.files[0]) {
    //   const file = ss.files[0];
    //   const reader = new FileReader();
    //   reader.onload = e => (this.editpdfSrc = reader.result);
    //   reader.readAsDataURL(file);
    // }
    var tis = this
    event.target.files.forEach((data, index)=>{
      var s = event.target.files[index].name.split(".")
      console.log(s[s.length-1])
      if(s[s.length-1] != "pdf"){
        event.target.value = ""
        return tis.api.popover("Only pdf files allowed")
      }
      tis.editpdfSrcs.push(event.target.files[index])
      if(event.target.files.length - 1 == index){
        console.log(tis.editpdfSrcs)
      }
    })
  }
  selected: any;
  select(item) {
    this.selected = item;
  };
  isActive(item) {
    return this.selected === item;
  };
  submitmediaPreview() {
    this.submitted = true;
    if (this.editmediaForm.invalid) {
      return;
    }
    var ss: any = document.getElementById("upload-media-file-edit");
    var pdfss: any = document.getElementById("upload-pdf-file-edit");
    var imagepath = ""
    var pdfpath = []
    if(this.editpdfSrc && this.editpdfSrc.length){
      this.editpdfSrc.forEach((data, index)=>{
        pdfpath.push(data)
      })
    }
    if (ss && ss.value) {
      var files: any = document.getElementById("upload-media-file-edit");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.subapp.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            imagepath = dd.body.imageUrl

          if (pdfss && pdfss.value) {
            var pdffiles: any = document.getElementById("upload-pdf-file-edit");
            var pdffile = pdffiles.files;
            if (pdffile == null) {
              return alert("No file selected.");
            }
            if(pdffile && pdffile.length){
              pdffile.forEach((pdfurl, indexpdf)=>{
                this.api
                .uploadimage("image", pdfurl, "pdf-upload", this.subapp.Lusername.S)
                .subscribe((event: HttpEvent<any>) => {
                  var dd: any = event;
                  if (dd && dd.body && dd.body.pdfUrl) {
                    pdfpath.push(dd.body.pdfUrl)
                    if(pdfpath.length - this.editpdfSrc.length == pdffile.length){
                      // For Both
                      this.submiteditmedia(imagepath, pdfpath);
                    }
                  }
                })
              })
            }
            }
            else{
              // For Both
              this.submiteditmedia(imagepath, this.editpdfSrc);
            }
          }
        });
    }
    else if(pdfss && pdfss.value && !ss.value){
      var pdffiles: any = document.getElementById("upload-pdf-file-edit");
      var pdffile = pdffiles.files;
      if (pdffile == null) {
        return alert("No file selected.");
      }
      if(pdffile && pdffile.length){
        pdffile.forEach((pdfurl, indexpdf)=>{
        this.api
          .uploadimage("image", pdfurl, "pdf-upload", this.subapp.Lusername.S)
          .subscribe((event: HttpEvent<any>) => {
            
            var dd: any = event;
            if (dd && dd.body && dd.body.pdfUrl) {
              pdfpath.push(dd.body.pdfUrl)
              // For Both
              if(pdfpath.length - this.editpdfSrc.length == pdffile.length){
                this.submiteditmedia(this.editimageSrc, pdfpath);
              }
            }
          })
        })
      }
    }    
    else {
      this.submiteditmedia(this.editimageSrc, this.editpdfSrc);
    }
  }
  removepdf(value, cc, index){
    if(value == 'editpdfSrc'){
      var pdff = this.edit_media_data.pdf_file.SS
      if(this.edit_media_data.pdf_file && this.edit_media_data.pdf_file.SS){
        // pdff = pdff.splice(index, 1)
        this.editpdfSrc.splice(index, 1)
        this.removemediapdf(this.editpdfSrc)
      }
      else{
        this.editpdfSrc = []
        this.removemediapdf([])
      }
    }
    else if(value == "obj_pdf_url"){
      this.obj_pdf_url = []
    }
    var image: any = document.getElementById(cc)
    image.value = ""
  }
  removemediapdf(pdfs){
    try {
      this.api
        .postmethod("user/delete/mediapdf", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S,
          xlusername: this.edit_media_data.CategoryRange.S,
          pdf_file: pdfs
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            // this.edit_media = false
            // this.edit_media_data = {}
            // this.editmediaForm.reset()
            // this.backlink(this.links.length - 1);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  submiteditmedia(media, pdfmedia) {
    var mm
    if (media) {
      mm = media
    }
    else {
      mm = "null"
    }
    var pdfmm
    if (pdfmedia && pdfmedia.length) {
      pdfmm = pdfmedia
    }
    else {
      pdfmm = []
    }
    try {
      this.api
        .postmethod("user/edit/media", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S,
          xlusername: this.edit_media_data.CategoryRange.S,
          title: this.editmediaForm.value.title,
          description: this.editmediaForm.value.description,
          status: this.editmediaForm.value.status,
          content_type: this.editmediaForm.value.type,
          media: mm,
          pdf_file: pdfmm,
          file: this.editmediaForm.value.file,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.edit_media = false
            this.edit_media_data = {}
            this.editmediaForm.reset()
            this.backlink(this.links.length - 1);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  fileOverBase(event): void {
    this.hasBaseDropZoneOver = event;
  }
  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map(fileItem => {
      return fileItem.file;
    });
  }

  upload() {
    let files = this.getFiles();
    let requests = [];
    files.forEach(file => {
      let formData = new FormData();
      formData.append("file", file.rawFile, file.name);
      //requests.push(this.uploadService.upload(formData));
    });

    concat(...requests).subscribe(
      res => {
      },
      err => {
      }
    );
  }
  fomrControlState(i) {
    const controlArray = this.mediaForm.get("formArrayName") as FormArray;
    return controlArray.controls[i].disabled;
  }
  mediaselected: any
  mediaisActive(item) {
    return this.mediaselected === item;
  };
  videoUrl: any
  videoSizeDuration: any = 0;
  readVideoUrl(event: any) {
    const files = event.target.files;
    if (files && files[0]) {
      this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    }
  }
  getDurations(e) {
    // this.videoSizeDuration = e.target.duration;
  }
  getDurationurl(e, video) {
    this.videoSizeDuration = video.duration
  }
  selectmovepolder(event){
    console.log(event)
  }
  move_media: any
  move_media_item: any
  onlyfolders: any = []
  movemedia(category){
    this.move_media = true
    this.move_media_item = category
    var tis = this
    var dd1 = 0
    tis.onlyfolders = []
    if(tis.allcategories && tis.allcategories.length){

    }
    else{
      return
    }
    tis.allcategories.forEach((data, indexs)=>{
      if(data.upload_type.S == "category" && data.parentcategory.S && data.parentcategory.S == "null"){
        tis.onlyfolders.push(data)
        // Step 1
          var indexes1 = tis.allcategories.map((val, index) => ({ val, index }))
          .filter(({val, index}) => val.parentcategory.S === data.CategoryRange.S && val.upload_type.S == "category")
          .map(({val, index}) => index)
          if(indexes1 && indexes1.length){
            tis.onlyfolders[dd1].childs = []
            indexes1.forEach((idata, iindex)=>{
              tis.onlyfolders[dd1].childs.push(tis.allcategories[idata])
              if(idata){
                // Step 2
                var indexes2 = tis.allcategories.map((val2, index2) => ({ val2, index2 }))
                .filter(({val2, index2}) => val2.parentcategory.S === tis.onlyfolders[dd1].childs[iindex].CategoryRange.S && val2.upload_type.S == "category")
                .map(({val2, index2}) => index2)
                if(indexes2 && indexes2.length){
                  tis.onlyfolders[dd1].childs[iindex].childs = []
                  indexes2.forEach((idata2, iindex2)=>{
                    tis.onlyfolders[dd1].childs[iindex].childs.push(tis.allcategories[idata2])
                    // Step 3
                    var indexes3 = tis.allcategories.map((val3, index3) => ({ val3, index3 }))
                    .filter(({val3, index3}) => val3.parentcategory.S === tis.onlyfolders[dd1].childs[iindex].childs[iindex2].CategoryRange.S && val3.upload_type.S == "category")
                    .map(({val3, index3}) => index3)
                    if(indexes3 && indexes3.length){
                      tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs = []
                      indexes3.forEach((idata3, iindex3)=>{
                        tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs.push(tis.allcategories[idata3])
                        // Step 4
                        var indexes4 = tis.allcategories.map((val4, index4) => ({ val4, index4 }))
                        .filter(({val4, index4}) => val4.parentcategory.S === tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs[iindex3].CategoryRange.S && val4.upload_type.S == "category")
                        .map(({val4, index4}) => index4)
                        if(indexes4 && indexes4.length){
                          tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs[iindex3].childs = []
                          indexes4.forEach((idata4, iindex4)=>{
                            tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs[iindex3].childs.push(tis.allcategories[idata4])
                            // Step 5
                            var indexes5 = tis.allcategories.map((val5, index5) => ({ val5, index5 }))
                            .filter(({val5, index5}) => val5.parentcategory.S === tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs[iindex3].childs[iindex4].CategoryRange.S && val5.upload_type.S == "category")
                            .map(({val5, index5}) => index5)
                            if(indexes5 && indexes5.length){
                              tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs[iindex3].childs[iindex4].childs = []
                              indexes5.forEach((idata5, iindex5)=>{
                                tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs[iindex3].childs[iindex4].childs.push(tis.allcategories[idata5])
                                // Step 6
                                var indexes6 = tis.allcategories.map((val6, index6) => ({ val6, index6 }))
                                .filter(({val6, index6}) => val6.parentcategory.S === tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs[iindex3].childs[iindex4].childs[iindex5].CategoryRange.S && val6.upload_type.S == "category")
                                .map(({val6, index6}) => index6)
                                if(indexes6 && indexes6.length){
                                  tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs[iindex3].childs[iindex4].childs[iindex5].childs = []
                                  indexes6.forEach((idata6, iindex6)=>{
                                    tis.onlyfolders[dd1].childs[iindex].childs[iindex2].childs[iindex3].childs[iindex4].childs[iindex5].childs.push(tis.allcategories[idata6])
                                  })
                                }
                              })
                            }
                          })
                        }
                      })
                    }
                  })
                }
              }
            })
          }
          dd1++;
      }
      if(tis.allcategories.length-1 == indexs){
        console.log(tis.onlyfolders)
        console.log(this.move_media_item)
      }
    })
  }
  cancelmediamove(){
    this.move_media = false
    this.move_media_item = {}
    this.doubleClickselect = {}
  }
  getDuration(file) {
    let videoNode = document.createElement("video");
    let promise = new Promise(function (resolve, reject) {
      videoNode.addEventListener("loadedmetadata", function () {
        resolve(videoNode.duration);
      });
      videoNode.addEventListener("error", function () {
        reject(videoNode.error.message + "(" + videoNode.error.code + ")");
      });
    });

    const URL = window.URL || window.webkitURL;
    videoNode.src = URL.createObjectURL(file);

    return promise;
  }

  onSubmit() {
    this.submitted = true;
    if (this.mediaForm.invalid) {
      return;
    }
    this.uploadmultiplemedia();
  }

  // uploadpdf(file){
  //   var filee: any = document.getElementById("uploadffp");
  //   this.api
  //   .uploadpdf("image", filee.files[0], "pdf-upload")
  //   .subscribe((event) => {
  //     
  //   });
  // }
  obj_image_url: any = ""
  obj_pdf_url: any = []
  onchangefileimageurl(evt: any){
    this.obj_image_url = evt.target.files[0];
  }
  onchangefilepdfurl(evt: any){
    var tis = this
    evt.target.files.forEach((data, index)=>{
      var s = evt.target.files[index].name.split(".")
      console.log(s[s.length-1])
      if(s[s.length-1] != "pdf"){
        evt.target.value = ""
        return tis.api.popover("Only pdf files allowed")
      }
      tis.obj_pdf_url.push(evt.target.files[index])
      if(evt.target.files.length - 1 == index){
        console.log(tis.obj_pdf_url)
      }
    })
  }
  obj_image: any = []
  obj_pdf: any = []
  onchangefileimage(evt: any, i){
    if(this.obj_image.findIndex(
      e => e.index == i
    ) > -1){
      this.obj_image[this.obj_image.findIndex(
        e => e.index == i
      )].file = evt.target.files[0]
    }
    else{
      var dd = {
        index: i,
        file: evt.target.files[0]
      }
      this.obj_image.push(dd)
    }
  }
  onchangefilepdf(evt: any, i){
    if(this.obj_pdf.findIndex(
      e => e.index == i
    ) > -1){
      this.obj_pdf[this.obj_image.findIndex(
        e => e.index == i
      )].file = evt.target.files
    }
    else{
      var dd = {
        index: i,
        file: evt.target.files
      }
      this.obj_pdf.push(dd)
    }
  }
  progress: any
  canceladdcategory() {
    this.add_category = false
    this.categoryForm.reset();
    // this.backlink(this.links.length - 2)
  }
  cancelmediaupload() {
    this.selectedfiles = []
    this.upload_media = false

    this.editpdfSrc = []
    this.editimageSrc = ""
    this.stopuploadFile()
    this.reseturlformdata()
    this.upload_media = false
    // this.backlink(this.links.length - 2)
  }
  updatemedia(recording, value, last, CategoryPK, CategoryRange, duration, image_file = 'null', pdf_file) {
    let categoryposition = '';
    // if (value.position != undefined) {
    //   //categoryposition = "'"+value.position+"'";
    //   //categoryposition = categoryposition.substr(0, 4);
    // }

    var media = {
      sessionid: this.store.get_token(),
      appid: this.store.getuserdata().appid.S,
      parentcategory: CategoryPK,
      category: CategoryRange,
      title: value.title,
      description: value.description,
      media: image_file,
      recording: recording,
      categorystatus: value.status,
      categoryposition: '',
      extensiontype: value.extensiontype,
      duration: parseInt(duration),
      userid: this.store.getuserdata().Lusername.S,
      // image_file: image_file,
      pdf_file: pdf_file,
      content_type: value.type
    };
    this.createm(media, last)
  }
  stopuploading(){
    clearInterval(this.progress)
    if (document.getElementsByClassName("saveactive") && document.getElementsByClassName("saveactive")[0] && document.getElementsByClassName("saveactive")[0].innerHTML) {
      document.getElementsByClassName("saveactive")[0].innerHTML = "100" + " %"
    }
    if (document.getElementsByClassName("saveactivee") && document.getElementsByClassName("saveactivee")[0] && document.getElementsByClassName("saveactivee")[0].innerHTML) {
      document.getElementsByClassName("saveactivee")[0].innerHTML = "100" + " %"
    }
  }
  createm(media, last) {
    try {
      this.api.postmethod("user/create/media", media).subscribe((res: any) => {
        this.mediaselected = '';
        if (res.status == "success" && res.code == 200) {
          if (last) {
            // this.api.popover(res.message);
            // this.loader.hide();

            this.stopuploading()
            setTimeout(() => {
              this.api.popover("Media Uploaded Successfully");
              if(this.requestabourt && this.requestabourt.length){
                for(var x = 0; x<this.requestabourt.length; x++){
                  this.requestabourt[x].abort()
                }
              }
              this.reseturlformdata()
              this.upload_media = false
              if (this.links.length > 0) {
                this.backlink(this.links.length - 1);
              } else {
                this.showparent()
                this.links = []
                this.regetcategories();
              }
            }, 600)
          }
        } else {
          // this.loader.hide();
          this.api.handle_Error(res);
        }
      });
    } catch (err) {
      this.mediaselected = '';
      this.loader.hide();
      this.api.handle_Error(err);
    }
  }
  result: string = "";
  confirmDialog(data, data1, type): void {
    var message = `Are you sure you want to delete?`;

    var dialogData = new ConfirmDialogModel("", message);

    var dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;

      if (this.result) {
        if (type == 'category') {
          this.deletecategory(data, data1);
        }
        else if (type == 'media') {
          this.deletemedia(data, data1);
        }
      }
    });
  }
  // Push notification
  sendpushnotification: boolean = false;
  pushcategory: any;
  submittedpush: boolean = false
  openpushnotification(category) {
    this.pushcategory = {}
    this.pushcategory = category
    this.sendpushnotification = true
  }
  closepushnoti() {
    this.pushcategory = {}
    this.sendpushnotification = false
  }

  goBack() {
    window.history.back();
  }

  gotopage(pagenumber) {
    this.selected = pagenumber
    if (this.lecturers.length > this.listqty) {
      this.lecturer_list = []
      this.lecturers.forEach((data, index) => {
        if (index >= this.api.getlength(pagenumber, this.listqty)[0] && index < this.api.getlength(pagenumber, this.listqty)[1]) {
          this.lecturer_list.push(data)
        }
        if(this.api.getlength(pagenumber, this.listqty)[1] == index - 1 ){
          this.lecturer_list.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
        }
        else{
        }
      })
    }
  }

  gotopagesub(pagenumber) {
    this.selected = pagenumber
    if (this.subcategorie.length > this.sublistqty) {
      this.subcategories = []
      this.subcategorie.forEach((data, index) => {
        if (index >= this.api.getlength(pagenumber, this.sublistqty)[0] && index < this.api.getlength(pagenumber, this.sublistqty)[1]) {
          this.subcategories.push(data)
        }
        if(this.api.getlength(pagenumber, this.sublistqty)[1] == index - 1 ){
          this.subcategories.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
        }
        else{
        }
      })
    }
  }

  listqty: any
  changeSelection() {
    this.lecturer_list = []
    var tis = this
    this.lecturers.forEach((data, index) => {
      if (index < this.listqty) {
        this.lecturer_list.push(data)
      }
      if (index == this.lecturers.length - 1) {
        if (tis.lecturers.length <= parseInt(tis.listqty)) {
          tis.pagenumber = [1]
          this.lecturer_list.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
        }
        else {
          tis.pagenumber = tis.api.getpagelength(
            tis.lecturers.length
          );
        }
      }
      if (index == this.listqty-1) {
        this.lecturer_list.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
      }
    })
  }

  sublistqty: any
  subchangeSelection() {
    this.subcategories = []
    var tis = this
    this.subcategorie.forEach((data, index) => {
      if (index < this.sublistqty) {
        this.subcategories.push(data)
      }
      if (index == this.subcategorie.length - 1) {
        if (tis.subcategorie.length <= parseInt(tis.sublistqty)) {
          tis.subpagenumber = [1]
          this.subcategories.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
        }
        else {
          tis.subpagenumber = tis.api.getpagelength(
            tis.subcategorie.length
          );
        }
      }
      if (index == this.sublistqty-1) {
        this.subcategories.sort(function(a: any, b: any){return parseInt(b.time_stamp.N)-parseInt(a.time_stamp.N)})
      }
    })
  }

  sendpushnotificationtoall() {
    this.submittedpush = true
    if (this.pushnotificationForm.invalid) {
      return;
    }
    var url = this.pushnotificationForm.value.url
    url = url.replace(/\s/g,'')
    var dd = {
      "sessionid": this.store.get_token(), 
      "appid":this.store.getuserdata().appid.S,
      "title": this.pushnotificationForm.value.title, 
      "body": this.pushnotificationForm.value.body, 
      "lusername": this.pushcategory.CategoryPK.S, 
      "xlusername": this.pushcategory.CategoryRange.S,
      "url": url
    }
    try {
      this.api.postmethod("user/create/pushnotification", dd).subscribe((res: any) => {
        if (res.status == "success" && res.code == 200) {
          this.closepushnoti()
          this.api.popover(res.message);
          this.pushnotificationForm.reset()
          this.submittedpush = false
        } else {
          // this.loader.hide();
          this.api.handle_Error(res);
        }
      });
    } catch (err) {
      this.mediaselected = '';
      this.loader.hide();
      this.api.handle_Error(err);
    }
  }

  fromurl: boolean=false
  uploadfromurl(){
    this.reseturlformdata()
    this.fromurl = true
    this.selectedfiles = []
  }
  uploadfromlocal(){
    this.fromurl = false
  }
  uploadfromurlm = ""
  onchangeurl(){
  }
  onSubmitURL(){
    if(this.uploadfromurlm.length>1){
      this.checkurl()
    }
    else{
      return this.api.popover("Please provide url")
    }
  }
  reseturlformdata(){
    this.uploadfromurlm = ""
    this.uploadfromurlForm.controls.title.setValue(
      ""
    );
    this.uploadfromurlForm.controls.description.setValue(
      ""
    );
    this.uploadfromurlForm.controls.filename.setValue(
      ""
    );
    this.uploadfromurlForm.controls.pdffile.setValue(
      ""
    );
    this.submitted = false
    this.uploadfromlocal()
    this.obj_image_url = ""
    this.obj_pdf_url = []
  }
  signInWithGoogle(): void {
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    // this.authService.authState.subscribe((user) => {
    //   this.store.setgoogletoken(user.authToken)
    // });
  }

  checkurl = () => {
    this.uploadfromurlm = this.uploadfromurlm.replace(/\s/g,'')
    // Dropbox
    console.log(this.uploadfromurlm)
    if(this.uploadfromurlm.includes("dropbox.com")){
      return this.api.popover("Currently not able to upload dropbox media.")
    }
    var extension = this.uploadfromurlm.split('.').pop(); 
    var exttype = ""
    if(
      extension == "mp4" || 
      extension == "mp3" || 
      extension == "ogg" || 
      extension == "webm" || 
      extension == "3gp" || 
      extension == "flv" || 
      extension == "wma" || 
      extension == "oga" || 
      extension == "ogv" || 
      extension == "ogx" || 
      extension == "mov" || 
      extension == "aac" || 
      extension == "pdf"){
      if(
        extension == "aac" || 
        extension == "mp3"
      ){
        exttype = "audio"
      }      
      else if(
        extension == "mp4" || 
        extension == "ogg" || 
        extension == "webm" || 
        extension == "3gp" || 
        extension == "flv" || 
        extension == "wma" || 
        extension == "oga" || 
        extension == "ogv" || 
        extension == "ogx" || 
        extension == "mov"){
        exttype = "video"
      }
      else if(
        extension == "pdf"){
        exttype = "pdf"
      }
    }
    else if(this.uploadfromurlm.includes("youtube.com")){
      exttype = "video"
    }
    else if(this.uploadfromurlm.includes("firebasestorage.googleapis.com")){
      exttype = "video"
    }
    else{
      return this.api.popover("Only pdf , video and audio are allowed.")
    }
    this.submitted = true;
    if(this.uploadfromurlForm.invalid){
      return 
    }
    this.mediaselected = 'submitmedia';
    var val = 0
    this.progress = setInterval(() => {
      document.getElementsByClassName("saveactivee")[0].innerHTML = val.toString() + " %"
      if (val > 98 && this.progress) {
        clearInterval(this.progress)
      }
      else {
        val++;
      }
    }, 1800)
    // if(this.uploadfromurlm.includes("https://drive.google.com/file/d/") && !this.store.getgoogletoken()){
    //   this.signInWithGoogle();
    // }
    if(this.uploadfromurlm.includes("youtube.com")){
      try {
        this.api.postmethod("upload-from-youtube-urls", { 
          url:this.uploadfromurlm, 
          token: this.store.getgoogletoken(), 
          appid: this.subapp.Lusername.S
        }).subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.videoSizeDuration = res.duration
            this.getSignedURL(res.imageUrl, exttype)
          } else {
            // this.loader.hide();
            this.api.handle_Error(res);
          }
        });
      } catch (err) {
        this.api.handle_Error(err);
      }
    }
    else if(this.uploadfromurlm.includes("firebasestorage.googleapis.com")){
      try {
        this.api.postmethod("upload-firebase-from-urls", { 
          url:this.uploadfromurlm, 
          token: this.store.getgoogletoken(), 
          appid: this.subapp.Lusername.S
        }).subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            var tis = this
            var myVideoPlayer: any = document.getElementById('video_player'),
            meta = document.getElementById('meta');
            myVideoPlayer.src = res.imageUrl
            exttype = res.type
            var count = 0 ;
            myVideoPlayer.addEventListener('loadedmetadata', function () {
              if(count == 0){
                var duration = myVideoPlayer.duration;
                tis.videoSizeDuration = duration
                tis.getSignedURL(res.imageUrl, exttype)
                count++
              }
            });
          } else {
            this.stopuploading()
            this.api.handle_Error(res);
          }
        });
      } catch (err) {
        this.stopuploading()
        this.api.handle_Error(err);
      }
    }
    else{
      try {
        this.api.postmethod("upload-from-url", { 
          url:this.uploadfromurlm, 
          token: this.store.getgoogletoken(), 
          appid: this.subapp.Lusername.S
        }).subscribe((res: any) => {
          console.log(res)
          if (res.status == "success" && res.code == 200) {
            this.getSignedURL(res.imageUrl, exttype)
          } else {
            // this.loader.hide();
            this.api.handle_Error(res);
          }
        });
      } catch (err) {
        this.api.handle_Error(err);
      }
    }
  };
  getSignedURL(media_url, exttype){
    var CategoryPK
    var CategoryRange
    if (this.links.length >= 1) {
      CategoryPK = this.links[this.links.length - 1].CategoryPK.S
      CategoryRange = this.links[this.links.length - 1].CategoryRange.S
    }
    else{
      CategoryPK = 'null'
      CategoryRange = 'null'
    }
    let pdf_file = [];
    let image_file = '';
    var ddd = {
      title: this.mm.title.value,
      description: this.mm.description.value,
      status: this.mm.status.value,
      extensiontype: exttype,
      type: this.mm.type.value,
    }
    if(this.obj_image_url && this.obj_pdf_url && this.obj_pdf_url.length){
      // Pdf File upload
      var pdffile = this.obj_pdf_url;
      var tis = this
      pdffile.forEach((pdfu, index)=>{
        tis.api
        .uploadpdf("image", pdfu, "pdf-upload", tis.subapp.Lusername.S)
        .subscribe((pdffilepath) => {
          var dd: any = pdffilepath;
          if (dd && dd.body && dd.body.pdfUrl) {
            pdf_file.push(dd.body.pdfUrl);
            if(pdffile.length == pdf_file.length){
            // Image upload
            var file = tis.obj_image_url;
            tis.api
              .uploadimage("image", file, "image-upload", tis.subapp.Lusername.S)
              .subscribe((event: HttpEvent<any>) => {
                var dd: any = event;
                if (dd && dd.body && dd.body.imageUrl) {
                  image_file = dd.body.imageUrl;
                  var indexType = tis.mm.value;
  
                // For both
                if (tis.selectedfiles.type === 'application/pdf') {
                  tis.updatemedia(media_url, ddd, true, CategoryPK, CategoryRange, "null", "", []);
                }
                else{
                  tis.updatemedia(media_url, ddd, true, CategoryPK, CategoryRange, tis.videoSizeDuration, image_file, pdf_file);
                }
                }
              });
            }
          }
        });  
      })          
    }
    else if(this.obj_image_url && !this.obj_pdf_url && !this.obj_pdf_url.length){
      pdf_file = [];
      // Image upload
      var file = this.obj_image_url;
      this.api
        .uploadimage("image", file, "image-upload", this.subapp.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            image_file = dd.body.imageUrl;
            var indexType = this.mm.value;

          // For both
          if (this.selectedfiles.type === 'application/pdf') {
            this.updatemedia(media_url, ddd, true, CategoryPK, CategoryRange, "null", "", []);
          }
          else{
              this.updatemedia(media_url, ddd, true, CategoryPK, CategoryRange, this.videoSizeDuration, image_file, pdf_file);
          }
        }
      });
    }
    else if(!this.obj_image_url && this.obj_pdf_url && this.obj_pdf_url.length){
      // Pdf File upload
      var pdffile = this.obj_pdf_url;
      var tis = this
      pdffile.forEach((pdfu, index)=>{
        this.api
        .uploadpdf("image", pdfu, "pdf-upload", this.subapp.Lusername.S)
        .subscribe((pdffilepath) => {
          var dd: any = pdffilepath;
          if (dd && dd.body && dd.body.pdfUrl) {
            pdf_file.push(dd.body.pdfUrl);
              if(pdffile.length == pdf_file.length){
                image_file = "";
                var indexType = this.mm.value;
              // For both
              if (this.selectedfiles.type === 'application/pdf') {
                this.updatemedia(media_url, ddd, true, CategoryPK, CategoryRange, "null", "", []);
              }
              else{
                this.updatemedia(media_url, ddd, true, CategoryPK, CategoryRange, this.videoSizeDuration, image_file, pdf_file);
              }
            }
          }
        }); 
      })    
    }
    else{
      this.updatemedia(media_url, ddd, true, CategoryPK, CategoryRange, this.videoSizeDuration, "", []);
    }
  }

  downloadmedia(media){
    if(media.extensiontype.S == 'video'){
      console.log("Video")
      console.log(media.extensiontype.S)
    }
    else if(media.extensiontype.S == 'audio'){
      console.log("Audio")
      console.log(media.extensiontype.S)
    }
    else if(media.extensiontype.S == 'pdf'){
      console.log("PDF")
      console.log(media.extensiontype.S)
    }
  }

  uploadmultiplemedia(){
    this.mediaselected = 'submitmedia';
    this.multiplelast = 0
    setTimeout(()=>{
      document.getElementsByClassName("saveactive")[0].innerHTML = "Uploading..."
      var fileList: any = document.getElementsByClassName("custom-file-input")[0];
      for (var x = 0; x < fileList.files.length; x++) {
        this.uploadFile(fileList.files[x], x)
      }
    }, 1000)
  }
  valueprogress: any = 0;
  multiplelast = 0
  requestabourt: any = []
  // keyid: any
  stopuploadFile(): void{
    this.mediaselected = ""
    if (document.getElementsByClassName("saveactive") && document.getElementsByClassName("saveactive")[0] && document.getElementsByClassName("saveactive")[0].innerHTML) {
      document.getElementsByClassName("saveactive")[0].innerHTML = '<span>Save</span>'
    }
    if(this.requestabourt && this.requestabourt.length){
      for(var x = 0; x<this.requestabourt.length; x++){
        this.requestabourt[x].abort()
      }
    }
  }
  // Upload to bucket
  bucket: any = new S3(
    {
      "accessKeyId": atob(this.aki),
      "secretAccessKey": atob(this.sky),
      region: 'us-east-2',
      httpOptions: {
          timeout: 30000000,
          connectTimeout: 5000000
      }
    }
  );
  
  upcontentName: any;
  uploadFile(file, index) {
    var tis = this;
    const contentType = file.type;
   tis.upcontentName = file.name;
   var medianamekey
   if(
    contentType == 'video/mp4'||
    contentType === "video/m4a" ||
    contentType === "video/WEBM" ||
    contentType === "video/OGV" ||
    contentType === "video/MOV" ||
    contentType === "video/quicktime" ||
    contentType === "video/FLV" ||
    contentType === "video/3gpp2"
   ){
      medianamekey = Date.now().toString()+".mp4"
     }
   else if(
     contentType === "audio/flac" ||
     contentType === "audio/mp4" ||
     contentType === "audio/mp3" ||
     contentType === "audio/mpeg" ||
     contentType === "audio/ogg" ||
     contentType === "audio/aac" ||
     contentType === "audio/wav" ||
     contentType === "audio/x-m4a"
   ){
      medianamekey = Date.now().toString()+".mp3"
     }
   else if(contentType == 'application/pdf'){
      medianamekey = Date.now().toString()+".pdf"
    }
      const params = {
        Bucket: 'shiurim-bucket-v2/'+tis.subapp.Lusername.S+'/multiple-media',
        Key: medianamekey,
        Body: file,
        // ACL: 'public-read',
        ContentType: contentType,
      };
      this.requestabourt.push(this.bucket.upload(params));   
      this.requestabourt[this.requestabourt.length-1].on('httpUploadProgress', function (evt) {
        console.log(evt)
        console.log(Math.trunc((evt.loaded / evt.total) * 100))
        tis.progressbars[index].progress = Math.trunc((evt.loaded / evt.total) * 100)
      })
      this.requestabourt[this.requestabourt.length-1].send((err, data) => {
        if (err) {   
          console.log(err)
        } else {
          // Success 
          if(tis.multiplelast == tis.selectedfiles.length - 1){
            tis.createmediamultiple(data.Location, index, true)
          }
          else{
            tis.createmediamultiple(data.Location, index, false)
          }
          tis.multiplelast++;
        }
      }); 
  }

  createmediamultiple(Location, index, islast){
    var CategoryPK
    var CategoryRange
    if (this.links.length >= 1) {
        CategoryPK = this.links[this.links.length - 1].CategoryPK.S
        CategoryRange = this.links[this.links.length - 1].CategoryRange.S
    }
    else{
      CategoryPK = "null"
      CategoryRange = "null"
    }

    let pdf_file = [];
    let image_file = '';
    if(this.obj_pdf.findIndex(
      e => e.index == index
    ) > -1 || this.obj_image.findIndex(
      e => e.index == index
    ) > -1){
      // Having Image and PDF
      if(this.obj_pdf.findIndex(
        e => e.index == index
      ) > -1 && this.obj_image.findIndex(
        e => e.index == index
      ) > -1){
      // Pdf File upload
      var pdffile = this.obj_pdf[this.obj_pdf.findIndex(
        e => e.index == index
      )].file;
      if(pdffile && pdffile.length){
        pdffile.forEach((pdffilea, indexpdf)=>{
          this.api
          .uploadpdf("image", pdffilea, "pdf-upload", this.subapp.Lusername.S)
          .subscribe((pdffilepath) => {
            var dd: any = pdffilepath;
            if (dd && dd.body && dd.body.pdfUrl) {
              pdf_file.push(dd.body.pdfUrl);
              if(pdffile.length == pdf_file.length){
              // Image upload
              var file = this.obj_image[this.obj_image.findIndex(
                e => e.index == index
              )].file;
              this.api
                .uploadimage("image", file, "image-upload", this.subapp.Lusername.S)
                .subscribe((event: HttpEvent<any>) => {
                  var dd: any = event;
                  if (dd && dd.body && dd.body.imageUrl) {
                    image_file = dd.body.imageUrl;
                    var indexType = this.m(index).value;
    
                  // For both
                  if (this.selectedfiles[index].type === 'application/pdf') {
                    this.updatemedia(Location, this.m(index).value, islast, CategoryPK, CategoryRange, "null", "", []);
                  }
                  else{
                    this.getDuration(this.selectedfiles[index]).then((duration) => {
                        this.updatemedia(Location, this.m(index).value, islast, CategoryPK, CategoryRange, duration, image_file, pdf_file);
                    });
                  }
                  }
                });
              }
            }
          });
        })
      }
      }
      // Having only pdf
      else if(this.obj_pdf.findIndex(
        e => e.index == index
      ) > -1 && this.obj_image.findIndex(
        e => e.index == index
      ) == -1){
      // Pdf File upload
      var pdffile = this.obj_pdf[this.obj_pdf.findIndex(
        e => e.index == index
      )].file;
      if(pdffile && pdffile.length){
        pdffile.forEach((pdffilea, indexpdf)=>{
          this.api
          .uploadpdf("image", pdffilea, "pdf-upload", this.subapp.Lusername.S)
          .subscribe((pdffilepath) => {
            var dd: any = pdffilepath;
            if (dd && dd.body && dd.body.pdfUrl) {
              pdf_file.push(dd.body.pdfUrl);
              if(pdffile.length == pdf_file.length){
                // For both
                if (this.selectedfiles[index].type === 'application/pdf') {
                  this.updatemedia(Location, this.m(index).value, islast, CategoryPK, CategoryRange, "null", "", []);
                }
                else{
                  this.getDuration(this.selectedfiles[index]).then((duration) => {
                      this.updatemedia(Location, this.m(index).value, islast, CategoryPK, CategoryRange, duration, image_file, pdf_file);
                  });
                }
              }
              }
            })
          })
        }
      }
      // Having only image
      else if(this.obj_pdf.findIndex(
        e => e.index == index
      ) == -1 && this.obj_image.findIndex(
        e => e.index == index
      ) > -1){
      // Image upload
      var file = this.obj_image[this.obj_image.findIndex(
        e => e.index == index
      )].file;
      this.api
        .uploadimage("image", file, "image-upload", this.subapp.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            image_file = dd.body.imageUrl;
            var indexType = this.m(index).value;

          // For both
          if (this.selectedfiles[index].type === 'application/pdf') {
            this.updatemedia(Location, this.m(index).value, islast, CategoryPK, CategoryRange, "null", "", []);
          }
          else{
            this.getDuration(this.selectedfiles[index]).then((duration) => {
                this.updatemedia(Location, this.m(index).value, islast, CategoryPK, CategoryRange, duration, image_file, pdf_file);
            });
          }
        }
      })
      }

    } else {
      var indexType = this.m(index).value;
      if (this.selectedfiles[index].type === 'application/pdf') {
        this.updatemedia(Location, this.m(index).value, islast, CategoryPK, CategoryRange, "null", "", []);
      }
      else{
        this.getDuration(this.selectedfiles[index]).then((duration) => {
            this.updatemedia(Location, this.m(index).value, islast, CategoryPK, CategoryRange, duration, image_file, pdf_file);
        });
      }
    }
  }
}















































