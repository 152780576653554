import { Component } from "@angular/core";
import { navItems } from "../../_nav";
import { ApiService } from "../../services/api.service";
import { Router } from "@angular/router";
import { StorageService } from "../../services/storage.service";
declare var jquery:any;
declare var $ :any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html"
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems;
  today: number = Date.now();

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  constructor(
    private api: ApiService,
    private routes: Router,
    private store: StorageService
  ) {
    this.navItems = navItems;
  }
  logout() {
    this.api.logout();
  }
  chngpass() {
    this.routes.navigate(["/changepassword"]);
  }
}
