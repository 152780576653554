import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {
  today: number = Date.now();
  loginForm: FormGroup;
  submitted = false;
  loading = false;
  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private store: StorageService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private loader: LoaderService
  ) {
    if(this.store.have_token()){
      this.router.navigate(["/dashboard"]);
    }
  }

  member = "";
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loader.show();
    try {
      this.api
        .login({
          username: this.loginForm.value.email,
          password: this.loginForm.value.password,
          timestamp: Date.now()
        })
        .subscribe(
          (res: any) => {
            if (res.status == "success") {
              this.store.set_token(res.SessionID);
              this.store.setuserdata(res.user);
              this.loader.hide();
              this.router.navigate(["/dashboard"]);
            } else {
              this.loader.hide();
              this.api.handle_Error(res);
            }
          },
          err => {
            this.loader.hide();
            console.log(err);
          }
        );
    } catch (err) {
      this.loader.hide();
    }
  }
}
