import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { StorageService } from "./services/storage.service";
import { CheckconnectionService } from "./services/checkconnection.service";
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  // tslint:disable-next-line
  selector: "body",
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor( private store: StorageService,private router: Router, private checkconnection: CheckconnectionService) {
    this.checkconnection.checkconnection();
    if(this.store.have_token()==false){
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
