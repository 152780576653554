import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  addmedia(){
    this.router.navigate(['/addmedia']);
  }
  public data: any[] = [{
    title: '',
    upload: '',
    status: '',
    image: ''
  }];

  addplus(){
    this.data.push({
      title: '',
      upload: '',
      status: '',
      image: ''
    });
  }
  removeAddress(i: number) {
    this.data.splice(i, 1);
  }

  cancelMedia(){
    this.router.navigate(['/addmedia'])
  }
}
