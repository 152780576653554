import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private store: StorageService,
    private api: ApiService,) { }
  appid: any = ""
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      console.log(params.get("appid"))
      if(params.get("appid")){
        this.appid = params.get("appid")
        this.getappid()
      }
    })
  }

  getappid(){
    try {
      this.api
        .getmethod("user/openapp/sharedapp?appid="+this.appid)
        .subscribe((res: any) => {
          console.log(res)
          if(res.redirect && res.redirecturl){
            window.location.href = res.redirecturl;
          }
        })

    } catch (err) {
      
    }
  }
}
