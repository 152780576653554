import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-list-categories",
  templateUrl: "./list-categories.component.html",
  styleUrls: ["./list-categories.component.scss"]
})
export class ListCategoriesComponent implements OnInit {
  constructor(
    private api: ApiService,
    private store: StorageService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getcategories();
  }
  // subLink(){
  //   this.router.navigate(['/subcatagory'])
  // }
  editList(data) {
    this.router.navigate(["/editcatagory"], {
      queryParams: {
        data: JSON.stringify(data)
      }
    });
  }
  addNew() {
    this.router.navigate(["/managecategories"]);
  }

  categories: any;
  getcategories() {
    try {
      this.api
        .postmethod("user/all/category", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          this.categories = [];
          var childarr = [];
          var childarr1 = [];
          var tis = this;
          if (res.status == "success" && res.code == 200) {
            // this.categories = res.data.Items;
            // find parent
            var cat = res.data.Items;
            if (cat && cat.length) {
              cat.forEach((data, index) => {
                if (data.parentcategory.S == "null") {
                  var dd = {
                    parent: data,
                    child: []
                  };
                  tis.categories.push(dd);
                  if (cat.length - 1 == index) {
                    if (childarr && childarr.length) {
                      childarr.forEach((cdata, cindex) => {
                        if (
                          tis.categories.findIndex(
                            e => e.parent.catid.S == cdata.parentcategory.S
                          ) > -1
                        ) {
                          var ddd = {
                            parent: cdata,
                            child: []
                          };
                          tis.categories[
                            tis.categories.findIndex(
                              e => e.parent.catid.S == cdata.parentcategory.S
                            )
                          ].child.push(ddd);
                          this.pagenumbers = [];
                          this.pagenumber = [];
                          this.pagenumbers = this.api.getpageslength(
                            this.categories.length
                          );
                          this.pagenumber = this.api.getpagelength(
                            this.categories.length
                          );
                        } else {
                          // Find Child
                          childarr1.push(cdata);
                        }
                        if (childarr.length - 1 == cindex) {
                          tis.insertchild(childarr1);
                        }
                      });
                    }
                  }
                } else {
                  // Find Child
                  childarr.push(data);
                }
              });
            }
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  pagenumbers: any;
  pagenumber: any;
  insertchild(arr) {
    var tis = this;
    this.categories.forEach((data, index) => {
      data.child.forEach((dataa, indexa) => {
        console.log(
          arr.findIndex(e => e.parentcategory.S == dataa.parent.catid.S)
        );
        if (
          arr.findIndex(e => e.parentcategory.S == dataa.parent.catid.S) > -1
        ) {
          var dd = {
            parent:
              arr[
                arr.findIndex(e => e.parentcategory.S == dataa.parent.catid.S)
              ],
            child: []
          };
          this.categories[index].child[
            arr.findIndex(e => e.parentcategory.S == dataa.parent.catid.S)
          ].child.push(dd);
          this.pagenumbers = [];
          this.pagenumber = [];
          this.pagenumbers = this.api.getpageslength(this.categories.length);
          this.pagenumber = this.api.getpagelength(this.categories.length);
        }
      });
    });
  }

  deletedeveloper(data: any, data1: any) {
    try {
      this.api
        .postmethod("user/delete/category", {
          sessionid: this.store.get_token(),
          appid: data,
          catid: data1
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.getcategories();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  result: string = "";
  confirmDialog(data, data1): void {
    var message = `Are you sure you want to do delete?`;

    var dialogData = new ConfirmDialogModel("Confirm Action", message);

    var dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;

      if (this.result) {
        this.deletedeveloper(data, data1);
      }
    });
  }
}
