import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBynumber'
})
export class OrderBynumberPipe implements PipeTransform {

  transform(value: any[]): any[] {
    // return value.sort((a: any, b: any) => b.categoryposition.N.localeCompare(a.categoryposition.N));
    return value.sort(function(a: any, b: any){return parseFloat(a.categoryposition.N)-parseFloat(b.categoryposition.N)})
}

}
