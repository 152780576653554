import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import * as $ from 'jquery';

@Component({
  selector: "app-list-users",
  templateUrl: "./list-users.component.html",
  styleUrls: ["./list-users.component.scss"]
})
export class ListUsersComponent implements OnInit {
  closeResult: string;
  searchText: any;
  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private store: StorageService
  ) {}

  ngOnInit(): void {
    this.get_app();
    this.getAllUsers();
  }

  goselected :any;
  goisActive(item) {
    return this.goselected === item;
  };

  bulk_action_data: any = ""

  bulkaction(){
    if(!this.bulk_action_data){
      return alert("Please select action")
    }
    var selectedbulk = []
    var selectedbulkdata = []
    $('input[class="bulk-action"]:checked').each(function() {
      selectedbulk.push(this.value)
   });
   if(selectedbulk && selectedbulk.length){
    selectedbulk.forEach((data, index)=>{
      selectedbulkdata.push(this.allUsers[parseInt(data)])
      if(index == selectedbulk.length-1){
        this.bulk_action(selectedbulkdata)
      }
    })
   }
   else{
    alert("Please select lecturers from list")
   }
  }

  bulk_action(list){
    this.goselected = 'go'; 
//   debugger
    try {
      this.api
        .postmethod("user/bulkaction/users", { sessionid: this.store.get_token(), list: list, action: this.bulk_action_data, appid: this.subapp.Lusername.S })
        .subscribe((res: any) => {
          this.goselected = ''; 
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            setTimeout(()=>{
              this.bulk_action_data = ""
              this.getAllUsers()
            }, 2000)
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.goselected = ''; 
      this.api.handle_Error(err);
    }
  }


  subapp: any
  get_app(){
    try {
     this.api
       .postmethod("app_cust/app", {
         sessionid: this.store.get_token(),
         appid: this.store.getuserdata().appid.S
       })
       .subscribe((res: any) => {
         if (res.status == "success" && res.code == 200) {
          this.subapp = res.data;
          console.log(this.subapp)
         } else {
           this.api.handle_Error(res);
         }
       });
     } catch (err) {
       this.api.handle_Error(err);
    }
   };

  open(data) {
    alert("View data of " + data.userid.S);
    // this.modalService
    //   .open(content, { ariaLabelledBy: "modal-basic-title" })
    //   .result.then(
    //     result => {
    //       this.closeResult = `Closed with: ${result}`;
    //     },
    //     reason => {
    //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //     }
    //   );
  }


	goBack() {
		window.history.back();
    }
    
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  allUsers: any;
  getAllUsers() {
    try {
      this.api
        .postmethod("user/listall/user", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.allUsers = res.data.Items;

            $(document).ready(function() {
              $("#checkAll").click(function () {
                console.log("click")
                $('input:checkbox').not(this).prop('checked', this.checked);
              });
            });
            // this.api.popover(res.message);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  selecteduser: any;
  selecteduserindex: any;
  updatetypes(event, index){
    this.selecteduserindex = index
    this.selecteduser = event
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    var afterthreemonths = today.setMonth( today.getMonth() + 3 )
    this.selecteduser.expirydate = new Date(afterthreemonths).toISOString().split('T')[0];
    console.log(this.selecteduser.user_type)
  }
  backedituser(){
    this.selecteduser = null
    this.selecteduserindex = null
  }
  updatetype(){
//   debugger
    if(this.selecteduser.user_type.S == "premium"){
      if(this.selecteduser.expirydate){

      }else{
        return
      }
    }
    try {
      this.api
        .postmethod("user/updatetype/user", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S,
          lusername: this.selecteduser.UsersPK.S,
          xlusername: this.selecteduser.UsersRange.S,
          user_type: this.selecteduser.user_type.S,
          expirydate: new Date(this.selecteduser.expirydate).getTime()/1000
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.allUsers[this.selecteduserindex].user_type.S = this.selecteduser.user_type.S
            this.backedituser()
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
}
