import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  editList(){
    this.router.navigate(['/editsub'])
  }
  editdata(){
    this.router.navigate(['/editcatagory'])
  }

  back(){
    this.router.navigate(['/allfolder'])
  }
  addfolder(){
    this.router.navigate(['/managecategories'])
  }
  addnew(){
    this.router.navigate(['/addmedia'])
  }
  drop($event){
    
  }
}
