import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-downloaded-media',
  templateUrl: './downloaded-media.component.html',
  styleUrls: ['./downloaded-media.component.scss']
})
export class DownloadedMediaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
