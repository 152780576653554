import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { AccMagaComponent } from "./views/acc-maga/acc-maga.component";
import { ManageCategoriesComponent } from "./views/manage-categories/manage-categories.component";
import { ListCategoriesComponent } from "./views/list-categories/list-categories.component";
import { EditCategoryComponent } from "./views/edit-category/edit-category.component";
import { SubCategoryComponent } from "./views/sub-category/sub-category.component";
import { EditSubComponent } from "./views/edit-sub/edit-sub.component";
import { ManageMediaComponent } from "./views/manage-media/manage-media.component";
import { ListMediaComponent } from "./views/list-media/list-media.component";
import { ViewStatisticsComponent } from "./views/view-statistics/view-statistics.component";
import { ManageUsersComponent } from "./views/manage-users/manage-users.component";
import { ListUsersComponent } from "./views/list-users/list-users.component";
import { AccessHistoryComponent } from "./views/access-history/access-history.component";
import { AppStatisticsComponent } from "./views/app-statistics/app-statistics.component";
import { EditListMediaComponent } from "./views/edit-list-media/edit-list-media.component";
import { AndroidAppComponent } from "./views/android-app/android-app.component";
import { IosAppComponent } from "./views/ios-app/ios-app.component";
import { FavoritesMediaComponent } from "./views/favorites-media/favorites-media.component";
import { DownloadedMediaComponent } from "./views/downloaded-media/downloaded-media.component";
import { NotificationComponent } from "./views/notification/notification.component";
import { ChangePasswordComponent } from "./views/change-password/change-password.component";
import { UploadFileComponent } from "./views/upload-file/upload-file.component";
import { MediaManagerComponent } from "./views/media-manager/media-manager.component";
import { MediaManagerChildComponent } from "./views/media-manager-child/media-manager-child.component";
import { MediaManageComponent } from './views/media-manage/media-manage.component';
import { AddFolderComponent } from './views/add-folder/add-folder.component';
import { AllFolderComponent } from './views/all-folder/all-folder.component';
import { AddMediaComponent } from './views/add-media/add-media.component';
import { EditMediaComponent } from './views/edit-media/edit-media.component';
import { EditListComponent } from './views/edit-list/edit-list.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ContentanalyticsComponent } from './views/contentanalytics/contentanalytics.component';
import { AppCustomizationComponent } from './views/app-customization/app-customization.component';
import { ShareComponent } from './views/share/share.component';

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404"
    }
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500"
    }
  },
  {
    path: "share/:appid",
    component: ShareComponent,
    data: {
      title: "Share Page"
    }
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page"
    }
  },
  {
    path: "register",
    component: RegisterComponent,
    data: {
      title: "Register Page"
    }
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home"
    },
    children: [
      {
        path: "base",
        loadChildren: () =>
          import("./views/base/base.module").then(m => m.BaseModule)
      },
      {
        path: "buttons",
        loadChildren: () =>
          import("./views/buttons/buttons.module").then(m => m.ButtonsModule)
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./views/chartjs/chartjs.module").then(m => m.ChartJSModule)
      },
      {
        path: "dashboard",
        component: DashboardComponent
      },
      // {
      //   path: "dashboard",
      //   loadChildren: () =>
      //     import("./views/dashboard/dashboard.module").then(
      //       m => m.DashboardModule
      //     )
      // },
      {
        path: "icons",
        loadChildren: () =>
          import("./views/icons/icons.module").then(m => m.IconsModule)
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./views/notifications/notifications.module").then(
            m => m.NotificationsModule
          )
      },
      {
        path: "theme",
        loadChildren: () =>
          import("./views/theme/theme.module").then(m => m.ThemeModule)
      },
      {
        path: "managecategories",
        component: ManageCategoriesComponent
      },
      {
        path: "listcategory",
        component: ListCategoriesComponent
      },
      {
        path: "editcatagory",
        component: EditCategoryComponent
      },
      {
        path: "subcatagory",
        component: SubCategoryComponent
      },
      {
        path: "editsub",
        component: EditSubComponent
      },
      {
        path: "managemedia",
        component: ManageMediaComponent
      },
      {
        path: "listmedia",
        component: ListMediaComponent
      },
      {
        path: "viewstatistics",
        component: ViewStatisticsComponent
      },
      {
        path: "manageusers",
        component: ManageUsersComponent
      },
      {
        path: "listusers",
        component: ListUsersComponent
      },
      {
        path: "accesshistory",
        component: AccessHistoryComponent
      },
      {
        path: "appstatic",
        component: AppStatisticsComponent
      },
      {
        path: "contentanalytics",
        component: ContentanalyticsComponent
      },
      {
        path: "appcustomization",
        component: AppCustomizationComponent
      },
      {
        path: "editlistmedia",
        component: EditListMediaComponent
      },
      {
        path: "androidapp",
        component: AndroidAppComponent
      },
      {
        path: "iosapp",
        component: IosAppComponent
      },
      {
        path: "favoritesmedia",
        component: FavoritesMediaComponent
      },
      {
        path: "downloadedmedia",
        component: DownloadedMediaComponent
      },
      {
        path: "notification",
        component: NotificationComponent
      },
      {
        path: "changepassword",
        component: ChangePasswordComponent
      },
      {
        path: "mediaupload",
        component: UploadFileComponent
      },
      {
        path: 'addmedia', component: AddMediaComponent
      },
      {
        path: 'editmedia', component: EditMediaComponent
      },
      {
        path: 'editlist', component: EditListComponent
      },
      {
        path: "mediamanager",
        component: MediaManagerComponent
      },
      {
        path: "mediamanagerchild",
        component: MediaManagerChildComponent
      },
      {
        path: 'addfolder', component: AddFolderComponent
      },
      {
        path: 'allfolder', component: AllFolderComponent
      },
      {
        path: "mediamange", component: MediaManageComponent
      },
      {
        path: "widgets",
        loadChildren: () =>
          import("./views/widgets/widgets.module").then(m => m.WidgetsModule)
      }
    ]
  },
  { path: "**", component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
