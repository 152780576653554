import { INavData } from "@coreui/angular";

var menu = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-speedometer"
  },
  {
    name: "Manage Media",
    url: "/mediamanager",
    icon: "icon-earphones"
  },
  {
    name: "App Customization",
    url: "/appcustomization",
    icon: "icon-list"
  },
  {
    name: "Manage Users",
    url: "/manageusers",
    icon: "icon-user",
    children: [
      {
        name: "List Users",
        url: "/listusers",
        icon: "icon-list"
      }
    ]
  },

  {
    name: "App Statistics ",
    url: "/appstatic",
    icon: "icon-grid",
    children: [
      {
        name: "Analytics",
        url: "/appstatic",
        icon: "icon-list"
      },
      {
        name: "Content Analytics",
        url: "/contentanalytics",
        icon: "icon-list"
      }
    ]
  },
  {
    name: "Notifications ",
    url: "/notification",
    icon: "icon-bell"
  },
  {
    divider: true
  }
];

export const navItems: INavData[] = menu;
