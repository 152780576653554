import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-app',
  templateUrl: './ios-app.component.html',
  styleUrls: ['./ios-app.component.scss']
})
export class IosAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
