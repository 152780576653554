import { Component, OnInit } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { StorageService} from '../../services/storage.service'
import { ApiService } from "../../services/api.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  current_date: Date = new Date()
  constructor(private store: StorageService,
    private api: ApiService,){
      this.getAllUsers();
      this.getmedias();
  }

  latestusers: any = []
  allUsers: any = [];
  loadingrecords: boolean = false
  getAllUsers() {
    try {
      this.api
        .postmethod("user/list/user", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            if(res.data.Count>0){
              this.allUsers = res.data.Items;
              this.latestusers = this.allUsers.sort(function(a: any, b: any){return parseInt(a.created.N)-parseInt(b.created.N)});
              this.latestusers = this.latestusers.reverse()
              if(this.latestusers.length>10){
                var latest = this.latestusers
                this.latestusers = []
                var tis = this
                latest.forEach((data, index)=>{
                  var tis = this
                  if(index<10){
                    this.latestusers.push(data)
                  }
                  if(index == 10){
                    // console.log(this.latestusers)
                    var ss = []
                    this.latestusers.forEach((data, index)=>{
                      ss.push(data.UsersPK.S)
                      if(index == this.latestusers.length-1){
                        tis.loadingrecords = true;
                        try {
                          this.api
                            .postmethod("user/selectuser/analytics", {
                              sessionid: this.store.get_token(),
                              appid: this.store.getuserdata().appid.S,
                              userarr: ss
                            })
                            .subscribe((res: any) => {
                              var tis = this
                              if (res.status == "success" && res.code == 200) {
                                if(res.data.Count > 0){
                                  var analytics = res.data.Items
                                  for(var x = 0; x<tis.latestusers.length; x++){
                                    const items = analytics.filter(item => 
                                      tis.latestusers[x].UsersPK.S == item.userlusername.S
                                    );
                                    var priceduration = 0
                                    if(items && items.length){
                                      items.forEach((data, index)=>{
                                        priceduration = priceduration + parseInt(data.top.S) - parseInt(data.fromp.S)
                                        if(items.length-1 == index){
                                          tis.latestusers[x].duration = priceduration;
                                        }
                                      })
                                    }
                                    else{
                                      tis.latestusers[x].duration = priceduration;
                                    }
                                  }
                                  this.loadingrecords = false
                                }
                                else{
                                  this.loadingrecords = false
                                }
                              } else {
                                this.loadingrecords = false
                                this.api.handle_Error(res);
                              }
                            });
                        } catch (err) {
                          this.loadingrecords = false
                          this.api.handle_Error(err);
                        }

                      }
                    })
                  }
                })
              }
              // Chart
              var current_date = new Date()
              var month = current_date.getMonth()+1
              var year = current_date.getFullYear()
              var days = new Date(year, month, 0).getDate()
              var tis = this
              for(var x = 1; x <= days; x++){
                var date = new Date(year, month, x).getDate()
                var month = new Date(year, month, x).getMonth()
                var year = new Date(year, month, x).getFullYear()
                var today = new Date(year, month, x)
                if(today.getDate()<current_date.getDate()){
                  var ss = this.allUsers.findIndex(x=>tis.toDateTime(parseInt(x.created.N)).getDate() == date && tis.toDateTime(parseInt(x.created.N)).getMonth() == month && tis.toDateTime(parseInt(x.created.N)).getFullYear() == year)
                }
              }
            }
            else{
              this.allUsers = []
              this.latestusers = []
            }
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  toDateTime(secs) {
    var t = new Date(secs); // Epoch
    return t;
}
  topmedias: any = [];
  medias: any = [];
  getmedias() {
    try {
      this.api
        .postmethod("user/onlymedia/media", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            if (res.data.Count>0) {
              this.medias = res.data.Items;
              this.topmedias = this.medias.sort(function(a: any, b: any){return parseInt(a.time_stamp.N)-parseInt(b.time_stamp.N)});
              this.topmedias = this.topmedias.reverse()
              if(this.topmedias.length>10){
                var latest = this.topmedias
                this.topmedias = []
                latest.forEach((data, index)=>{
                  if(index<10){
                    this.topmedias.push(data)
                  }
                })
              }
            }
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  public play_media: boolean = false;
  public play_media_data: any;
  playmedia(data) {
    this.play_media = true;
    this.play_media_data = data
  }

  closemedia() {
    this.play_media = false;
    this.play_media_data = {};
  }




  radioModel: any = 'Month';

  // lineChart1
  public lineChart1Data: Array<any> = [
    {
      data: [65, 59, 84, 84, 51, 55, 40],
      label: 'Series A'
    }
  ];
  public lineChart1Labels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChart1Options: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'transparent',
          zeroLineColor: 'transparent'
        },
        ticks: {
          fontSize: 2,
          fontColor: 'transparent',
        }

      }],
      yAxes: [{
        display: false,
        ticks: {
          display: false,
          min: 40 - 5,
          max: 84 + 5,
        }
      }],
    },
    elements: {
      line: {
        borderWidth: 1
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false
    }
  };
  public lineChart1Colours: Array<any> = [
    {
      backgroundColor: getStyle('--primary'),
      borderColor: 'rgba(255,255,255,.55)'
    }
  ];
  public lineChart1Legend = false;
  public lineChart1Type = 'line';

  // lineChart2
  public lineChart2Data: Array<any> = [
    {
      data: [1, 18, 9, 17, 34, 22, 11],
      label: 'Series A'
    }
  ];
  public lineChart2Labels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChart2Options: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'transparent',
          zeroLineColor: 'transparent'
        },
        ticks: {
          fontSize: 2,
          fontColor: 'transparent',
        }

      }],
      yAxes: [{
        display: false,
        ticks: {
          display: false,
          min: 1 - 5,
          max: 34 + 5,
        }
      }],
    },
    elements: {
      line: {
        tension: 0.00001,
        borderWidth: 1
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false
    }
  };
  public lineChart2Colours: Array<any> = [
    { // grey
      backgroundColor: getStyle('--info'),
      borderColor: 'rgba(255,255,255,.55)'
    }
  ];
  public lineChart2Legend = false;
  public lineChart2Type = 'line';


  // lineChart3
  public lineChart3Data: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40],
      label: 'Series A'
    }
  ];
  public lineChart3Labels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChart3Options: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false
      }],
      yAxes: [{
        display: false
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
    legend: {
      display: false
    }
  };
  public lineChart3Colours: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,255,.2)',
      borderColor: 'rgba(255,255,255,.55)',
    }
  ];
  public lineChart3Legend = false;
  public lineChart3Type = 'line';


  // barChart1
  public barChart1Data: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40, 78, 81, 80, 45, 34, 12, 40, 12, 40],
      label: 'Series A',
      barPercentage: 0.6,
    }
  ];
  public barChart1Labels: Array<any> = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
  public barChart1Options: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false
      }]
    },
    legend: {
      display: false
    }
  };
  public barChart1Colours: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,255,.3)',
      borderWidth: 0
    }
  ];
  public barChart1Legend = false;
  public barChart1Type = 'bar';

  // mainChart

  public mainChartElements;
  public mainChartData1: Array<number> = [];
  // public mainChartData2: Array<number> = [];
  // public mainChartData3: Array<number> = [];

  public mainChartData: Array<any> = [
    {
      data: this.mainChartData1,
      label: 'Current'
    },
    // {
    //   data: this.mainChartData2,
    //   label: 'Previous'
    // },
    // {
    //   data: this.mainChartData3,
    //   label: 'BEP'
    // }
  ];
  /* tslint:disable:max-line-length */
  public mainChartLabels: Array<any> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Thursday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  /* tslint:enable:max-line-length */
  public mainChartOptions: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips,
      intersect: true,
      mode: 'index',
      position: 'nearest',
      callbacks: {
        labelColor: function(tooltipItem, chart) {
          return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function(value: any) {
            return value.charAt(0);
          }
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          stepSize: Math.ceil(250 / 5),
          max: 250
        }
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: false
    }
  };
  public mainChartColours: Array<any> = [
    { // brandInfo
      backgroundColor: hexToRgba(getStyle('--info'), 10),
      borderColor: getStyle('--info'),
      pointHoverBackgroundColor: '#fff'
    },
    { // brandSuccess
      backgroundColor: 'transparent',
      borderColor: getStyle('--success'),
      pointHoverBackgroundColor: '#fff'
    },
    { // brandDanger
      backgroundColor: 'transparent',
      borderColor: getStyle('--danger'),
      pointHoverBackgroundColor: '#fff',
      borderWidth: 1,
      borderDash: [8, 5]
    }
  ];
  public mainChartLegend = false;
  public mainChartType = 'line';

  // social box charts

  public brandBoxChartData1: Array<any> = [
    {
      data: [65, 59, 84, 84, 51, 55, 40],
      label: 'Facebook'
    }
  ];
  public brandBoxChartData2: Array<any> = [
    {
      data: [1, 13, 9, 17, 34, 41, 38],
      label: 'Twitter'
    }
  ];
  public brandBoxChartData3: Array<any> = [
    {
      data: [78, 81, 80, 45, 34, 12, 40],
      label: 'LinkedIn'
    }
  ];
  public brandBoxChartData4: Array<any> = [
    {
      data: [35, 23, 56, 22, 97, 23, 64],
      label: 'Google+'
    }
  ];

  public brandBoxChartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public brandBoxChartOptions: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false,
      }]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: false
    }
  };
  public brandBoxChartColours: Array<any> = [
    {
      backgroundColor: 'rgba(255,255,255,.1)',
      borderColor: 'rgba(255,255,255,.55)',
      pointHoverBackgroundColor: '#fff'
    }
  ];
  public brandBoxChartLegend = false;
  public brandBoxChartType = 'line';

  public random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  ngOnInit(): void {
  //  console.log( this.store.user_type())
  this.radioModel = 'Month'
    for (let i = 0; i <= this.mainChartElements; i++) {
      this.mainChartData1.push(this.random(50, 200));
      console.log(this.mainChartData1)
      // this.mainChartData2.push(this.random(80, 100));
      // this.mainChartData3.push(65);
    }
  }
}
