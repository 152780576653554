import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import * as $ from 'jquery';

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"]
})
export class NotificationComponent implements OnInit {
  constructor(private api: ApiService, private store: StorageService) {}

  ngOnInit(): void {
    this.getAllNotifications();
  }

	goBack() {
		window.history.back();
    }
    
  searchText: any;

  isActive(item) {
    return this.selected === item;
  };
  allNotifications: any = [];
  allNotifications_list: any = [];
  pagenumbers: any = ''
  pagenumber: any = ''
  selected: any;
  getAllNotifications() {
    try {
      this.api
        .postmethod("user/list/notification", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.allNotifications = res.data.Items;
            // this.api.popover(res.message);
            // this.allNotifications = []
            // this.allNotifications_list = res.data.Items
            // this.listqty = 10
            // this.selected = 1
            
            // this.allNotifications_list.forEach((data, index)=>{
            //   if(index<this.listqty){
            //     this.allNotifications.push(data);
            //   }
            // });
            // console.log(this.allNotifications);
            // this.pagenumbers = '';
            // this.pagenumber = '';
            // this.pagenumbers = this.api.getpageslength(
            //    this.allNotifications_list.length
            // );

            // this.pagenumber = this.api.getpagelength(
            //    this.allNotifications_list.length
            // );
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  listqty: any
  changeSelection(){
    this.allNotifications = []
    var tis = this
    this.allNotifications_list.forEach((data, index)=>{
      if(index<tis.listqty){
        tis.allNotifications.push(data)
      }
      if(index == tis.allNotifications_list.length - 1){
        if(tis.allNotifications_list.length<=parseInt(tis.listqty)){
          tis.pagenumber = [1]
        }
        else{
          tis.pagenumber = tis.api.getpagelength(
            tis.allNotifications_list.length
          );
        }
      }
     })
  }
  selectedusernoti: any;
  selecteduser: any;
  selecteduserindex: any;
  editnotification(event, index){
    console.log(event)
    this.selectedusernoti = event;
    this.selecteduserindex = index;
    this.selecteduser = event.user[0]
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    var afterthreemonths = today.setMonth( today.getMonth() + 3 )
    if(event.user[0].user_type.S == 'standard'){
      this.selecteduser.expirydate = new Date(afterthreemonths).toISOString().split('T')[0];
    }
    else{
      this.selecteduser.expirydate = new Date(afterthreemonths).toISOString().split('T')[0];
    }
    console.log(this.selecteduser.user_type)
  }
  
  backedituser(){
    this.selecteduser = null
    this.selecteduserindex = null
    this.selectedusernoti = null;
  }
  updatetype(){
//   debugger
    if(this.selecteduser.user_type.S == "premium"){
      if(this.selecteduser.expirydate){

      }else{  
        return
      }
    }
    try {
      this.api
        .postmethod("user/update/notification/status", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S,
          lusername: this.selecteduser.UsersPK.S,
          xlusername: this.selecteduser.UsersRange.S,
          user_type: this.selecteduser.user_type.S,
          expirydate: new Date(this.selecteduser.expirydate).getTime()/1000,
          notificationpk: this.selectedusernoti.NotificationPK.S,
          notificationrange: this.selectedusernoti.NotificationRange.S,
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.getAllNotifications();
            this.backedituser();
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  saveupdate(notification){
    try {
      this.api
        .postmethod("user/update/notification/status", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.allNotifications = res.data.Items;
            console.log(this.allNotifications);
            // this.api.popover(res.message);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
}
