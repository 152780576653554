import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";

@Component({
  selector: "app-edit-list-media",
  templateUrl: "./edit-list-media.component.html",
  styleUrls: ["./edit-list-media.component.scss"]
})
export class EditListMediaComponent implements OnInit {
  sub: any;
  mediaForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private store: StorageService
  ) {
    this.get_app()
  }

  subapp: any
  get_app(){
    try {
     this.api
       .postmethod("app_cust/app", {
         sessionid: this.store.get_token(),
         appid: this.store.getuserdata().appid.S
       })
       .subscribe((res: any) => {
         if (res.status == "success" && res.code == 200) {
          this.subapp = res.data;
          console.log(this.subapp)
         } else {
           this.api.handle_Error(res);
         }
       });
     } catch (err) {
       this.api.handle_Error(err);
    }
   };

  ngOnInit(): void {
    this.getcategories();
    var query: any = this.route.queryParams;
    console.log(JSON.parse(query.value.data));
    this.sub = JSON.parse(query.value.data);
    this.mediaForm = this.formBuilder.group({
      title: [this.sub.title.S, [Validators.required]],
      category: [this.sub.category.S, [Validators.required]],
      description: [this.sub.description.S],
      status: [this.sub.mediastatus.S],
      date: [""]
    });
    if (this.sub.media && this.sub.media.S && this.sub.media.S != "null") {
      this.imageSrc = this.sub.media.S;
    } else {
      this.imageSrc = "";
    }
  }

  imageSrc: any;
  readURL(event: Event): void {
    var ss: any = event.target;
    if (ss.files && ss.files[0]) {
      const file = ss.files[0];

      const reader = new FileReader();
      reader.onload = e => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
    }
  }
  get f() {
    return this.mediaForm.controls;
  }
  categories: any;
  getcategories() {
    try {
      this.api
        .postmethod("user/all/category", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.categories = res.data.Items;
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  submitPreview() {
    this.submitted = true;
    if (this.mediaForm.invalid) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
    var appid;
    if (this.store.getuserdata().appid && this.store.getuserdata().appid.S) {
      appid = this.store.getuserdata().appid.S;
    }
    var ss: any = document.getElementById("upload-media-file1");
    if (ss && ss.value) {
      var files: any = document.getElementById("upload-media-file1");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.subapp.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
        //   debugger;
            this.submit(appid, dd.body.imageUrl);
          }
        });
    } else {
      this.submit(appid, this.imageSrc);
    }
  }

  submit(appid, imageurl) {
//   debugger;
    try {
      this.api
        .postmethod("user/edit/category", {
          sessionid: this.store.get_token(),
          appid: appid,
          category: this.mediaForm.value.category,
          title: this.mediaForm.value.title,
          description: this.mediaForm.value.description,
          status: this.mediaForm.value.status,
          date_time: Date.now(),
          media: imageurl,
          recording: imageurl
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            // this.router.navigate(["/listcategory"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
}
