import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FileUploader, FileLikeObject } from "ng2-file-upload";
import { concat } from "rxjs";

@Component({
  selector: "app-add-media",
  templateUrl: "./add-media.component.html",
  styleUrls: ["./add-media.component.scss"]
})
export class AddMediaComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({});
  public hasBaseDropZoneOver: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  fileOverBase(event): void {
    this.hasBaseDropZoneOver = event;
  }
  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map(fileItem => {
      return fileItem.file;
    });
  }

  upload() {
    let files = this.getFiles();
    console.log(files);
    let requests = [];
    files.forEach(file => {
      let formData = new FormData();
      formData.append("file", file.rawFile, file.name);
      //requests.push(this.uploadService.upload(formData));
    });

    concat(...requests).subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log(err);
      }
    );
  }

  editdata() {
    this.router.navigate(["/editmedia"]);
  }
  addfolder() {
    this.router.navigate(["/mediaupload"]);
  }
  back() {
    this.router.navigate(["/addfolder"]);
  }
  addnew() {
    this.router.navigate(["/mediamange"]);
  }

  drop($event) {}
}
