import { Injectable } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';

@Injectable({
  providedIn: 'root'
})
export class CheckconnectionService {
  hasNetworkConnection: boolean;
  hasInternetAccess: boolean;
  status: string;
  constructor(private connectionService: ConnectionService) { }
  checkconnection(){
    this.connectionService.monitor().subscribe(currentState => {
      console.log(currentState);
      // this.hasNetworkConnection = currentState.hasNetworkConnection;
      // this.hasInternetAccess = currentState.hasInternetAccess;
      if (currentState) {
        this.status = 'ONLINE';
        this.hasInternetAccess = true;
      } else {
        this.status = 'OFFLINE';
        this.hasInternetAccess = false;
      }
    });
  }
  isconnectedtointernet(){
    return this.hasInternetAccess;
  }
}
