import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: 'app-app-statistics',
  templateUrl: './app-statistics.component.html',
  styleUrls: ['./app-statistics.component.scss']
})
export class AppStatisticsComponent implements OnInit {

  constructor( private api: ApiService,
    private store: StorageService,) { 
  }

  ngOnInit(): void {
  }


  updatefilterfrom(){
    // // Create new Date instance
    // var date = new Date(this.filterfrom)
    // // Add a day
    // date.setDate(date.getDate() + 1)
    // var month 
    // if(date.getMonth()+1>9){
    //   month = date.getMonth()+1
    // }
    // else{
    //   month = "0"+(date.getMonth()+1)
    // }
    // var datee
    // if(date.getDate()>9){
    //   datee = date.getDate()
    // }
    // else{
    //   datee = "0"+(date.getDate())
    // }
    // this.filterto = date.getFullYear()+"-"+month+"-"+datee
  }

	goBack() {
		window.history.back();
	  }
  filterfrom: any = new Date().toISOString().substr(0, 10);
  filterto: any = new Date().toISOString().substr(0, 10);
  groupby: any = "day";
  grouplist: any = [];
  filteranalytics(){
    if(new Date(this.filterto).getTime()<new Date(this.filterfrom).getTime()){
      this.api.popover("To date cannot be greater than from date")
      return 
    }
    if(this.filterfrom && this.filterto && this.groupby){
     this.showfilter()
    }
    else{
      this.api.popover("Please provide filter from date, filter to date and group by")
      return 
    }
  }

  showfilter(){
    var y = 0
    this.grouplist = []
    this.grouplist.push("Name")
    if(this.groupby == 'day'){
      for(var x = new Date(this.filterfrom).getTime(); x<=new Date(this.filterto).getTime() ; x){
        var date = new Date(this.filterfrom)
        this.grouplist.push(new Date(date.setDate(date.getDate() + y)).getTime())
        x = x+86400000
        y++
      }
    }
    console.log(this.grouplist)
    this.getanalytics()
  }

  fetchingdata: boolean = false;
  analyticslist: any = []
  analyticslists: any = []
  searched : boolean = false
  getanalytics(){
    this.analyticslists = []
    this.fetchingdata = true
    console.log(this.fetchingdata)
    setTimeout(()=>{
      this.fetchingdata = false
    }, 600000)
    try {
      this.api
        .postmethod("user/update/analytics", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S,
          fromdate: new Date(this.filterfrom).getTime(),
          todate: new Date(this.filterto).getTime()
        })
        .subscribe((res: any) => {
          this.searched = true
          var tis = this
          this.fetchingdata = false
          if(res.status == "success" && res.code == 200){
            if(res.data.Count==0){
              tis.analyticslist = []
            }
            else{
              tis.analyticslist = res.data.Items
              tis.analyticslist = tis.analyticslist.sort(function(a: any, b: any){return parseInt(a.createdate.N)-parseInt(b.createdate.N)});
              // Insert Names
              if(tis.groupby == 'day'){
                tis.filterbyday()
              }
              else if(tis.groupby == 'month'){
              }
              else if(tis.groupby == 'year'){
              }
          }
        }
      },
      error=>{
        this.fetchingdata = false
        console.log(error)
      });
    } catch (err) {
      this.fetchingdata = false
      this.api.handle_Error("Not able to reorder due to the internet speed, Please reorder the file again to fix this position");
    }
  }

  filterbyday(){
    var tis = this
    for(var x = 0; x<tis.analyticslist.length; x++){
      if(tis.analyticslists.findIndex(e=>e.userlusername == tis.analyticslist[x].userlusername.S && e.userxlusername == tis.analyticslist[x].userxlusername.S)>-1){
      }
      else{
        if(tis.analyticslist[x] && tis.analyticslist[x].user && tis.analyticslist[x].user.length){
          tis.analyticslists.push({userlusername: tis.analyticslist[x].userlusername.S, userxlusername: tis.analyticslist[x].userxlusername.S,"username":tis.analyticslist[x].user[0].firstname.S+" "+tis.analyticslist[x].user[0].lastname.S, grouplist: []})
        }
      }
        if(tis.analyticslist.length - 1 == x){
          for(var zz = 0; zz<tis.analyticslists.length; zz++){
            for(var z = 0; z<tis.grouplist.length; z++){
              var tis = this
            if(tis.grouplist[z] != 'Name'){
              var headdate = new Date(tis.grouplist[z]).getDate()
              var headmonth = new Date(tis.grouplist[z]).getMonth()
              var headyear = new Date(tis.grouplist[z]).getFullYear()
              const items = tis.analyticslist.filter(item => 
                new Date(parseInt(item.createdate.N)).getDate().toString().indexOf(headdate.toString() ) !== -1 
                && 
                new Date(parseInt(item.createdate.N)).getMonth().toString().indexOf(headmonth.toString() ) !== -1 
                &&
                new Date(parseInt(item.createdate.N)).getFullYear().toString().indexOf(headyear.toString() ) !== -1 
                &&
                tis.analyticslists[zz].userlusername == item.userlusername.S
                &&
                tis.analyticslists[zz].userxlusername == item.userxlusername.S
              );
              var priceduration = 0
              if(items && items.length){
                items.forEach((data, index)=>{
                  if(new Date(parseInt(data.createdate.N)).getDate() == headdate
                  && new Date(parseInt(data.createdate.N)).getMonth() == headmonth
                  && new Date(parseInt(data.createdate.N)).getFullYear() == headyear
                  ){
                    priceduration = priceduration + parseInt(data.top.S) - parseInt(data.fromp.S)
                  }
                  if(items.length-1 == index){
                    tis.analyticslists[zz].grouplist.push({date: headdate,month: headmonth,year: headyear, userlusername: data.userlusername.S, userxlusername: data.userxlusername.S,duration:priceduration});
                  }
                })
              }
              else{
                tis.analyticslists[zz].grouplist.push({date: headdate,month: headmonth,year: headyear,duration:priceduration});
              }
            }
          }
        }
      }
    }
  }
}
