import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtername'
})
export class FilternamePipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
searchText = searchText.toLowerCase();
return items.filter( it => {
        if(it && it.category && it.category.S && it.category.S != 'null'){
          return it.category.S.toLowerCase().includes(searchText); 
        }
        else{
          return it.title.S.toLowerCase().includes(searchText);
        }
    });
   }
}
