import { Component, OnInit } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Router } from "@angular/router";

@Component({
  selector: "app-add-folder",
  templateUrl: "./add-folder.component.html",
  styleUrls: ["./add-folder.component.scss"]
})
export class AddFolderComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  addfolder() {
    this.router.navigate(["/managecategories"]);
  }

  drop(event: CdkDragDrop<string[]>) {
    // moveItemInArray();
  }
  addmedia() {
    this.router.navigate(["/addmedia"]);
  }
}
