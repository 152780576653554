import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: "app-manage-categories",
  templateUrl: "./manage-categories.component.html",
  styleUrls: ["./manage-categories.component.scss"]
})
export class ManageCategoriesComponent implements OnInit {
  submitted = false;
  categoryForm: FormGroup;
  constructor(
    private api: ApiService,
    private store: StorageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.get_app()
  }

  ngOnInit(): void {
    this.getcategories();
    this.categoryForm = this.formBuilder.group({
      category: ["", [Validators.required]],
      parentcategory: [""],
      image: [""],
      description: [""],
      categoryposition: [""],
      categorystatus: [""]
    });
    document.getElementById("upload-media-file").onchange = function(e) {
      // console.log(e);
      // var imgg: any = e.target;
      // var img: any = document.getElementById("upload-media-image");
      // img.src = imgg.result;
    };
  }
  imageSrc: any;
  readURL(event: Event): void {
    var ss: any = event.target;
    if (ss.files && ss.files[0]) {
      const file = ss.files[0];

      const reader = new FileReader();
      reader.onload = e => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
    }
  }
  uploadimage(file) {
    console.log(file);
  }
  get f() {
    return this.categoryForm.controls;
  }
  resetPreview(data) {}
  categories: any;
  getcategories() {
    try {
      this.api
        .postmethod("user/all/category", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.categories = res.data.Items;
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  ngSubmit() {
    this.router.navigate(["/allfolder"]);
  }

  wresetPreview() {
    this.router.navigate(["/addfolder"]);
  }


  sub: any;
  get_app(){
    try {
     this.api
       .postmethod("app_cust/app", {
         sessionid: this.store.get_token(),
         appid: this.store.getuserdata().appid.S
       })
       .subscribe((res: any) => {
         if (res.status == "success" && res.code == 200) {
          this.sub = res.data;
         } else {
           this.api.handle_Error(res);
         }
       });
     } catch (err) {
       this.api.handle_Error(err);
    }
   };
  submitPreview() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return;
    }
    var appid;
    if (this.store.getuserdata().appid && this.store.getuserdata().appid.S) {
      appid = this.store.getuserdata().appid.S;
    }
    var ss: any = document.getElementById("upload-media-file");
    if (ss && ss.value) {
      var files: any = document.getElementById("upload-media-file");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          console.log(event);
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
        //   debugger;
            this.submit(appid, dd.body.imageUrl);
          }
        });
    } else {
      this.submit(appid, "");
    }
  }
  submit(appid, imageurl) {
    try {
      this.api
        .postmethod("user/create/category", {
          sessionid: this.store.get_token(),
          appid: appid,
          category: this.categoryForm.value.category,
          parentcategory: this.categoryForm.value.parentcategory,
          categorystatus: this.categoryForm.value.categorystatus,
          categoryposition: this.categoryForm.value.categoryposition,
          description: this.categoryForm.value.description,
          medias: imageurl
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.router.navigate(["/allfolder"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
}
