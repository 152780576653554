import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from "../../confirm-dialog/confirm-dialog.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-media-manager-child",
  templateUrl: "./media-manager-child.component.html",
  styleUrls: ["./media-manager-child.component.scss"]
})
export class MediaManagerChildComponent implements OnInit {
  page: any = "category";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private store: StorageService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    var query: any = this.route.queryParams;
    this.child = JSON.parse(query.value.data).child;
    this.category = JSON.parse(query.value.data).category;
    if (this.child) {
      this.getcategories(this.child);
    }
  }
  child: any;
  category: any;
  ngOnInit(): void {}
  ngOnChanges(): void {}
  ngOnDestroy(): void {}
  categories: any;
  getcategories(child) {
    try {
      this.api
        .postmethod("user/child/category", {
          sessionid: this.store.get_token(),
          child: child,
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          var tis = this;
          if (res.status == "success" && res.code == 200) {
            this.categories = res.data.Items;
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  addUpload() {
    this.router.navigate(["/managemedia"]);
  }
  addFolder() {
    this.router.navigate(["/managecategories"]);
  }
  gotochild(category, child) {
    try {
      this.api
        .postmethod("user/child/category", {
          sessionid: this.store.get_token(),
          child: child,
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          var tis = this;
          if (res.status == "success" && res.code == 200) {
            if (res.data.Items && res.data.Items.length) {
              var cat = this.category;
              cat.push(category);
              if (category != "null" && child) {
                this.router.navigate(["/mediamanagerchild"], {
                  queryParams: {
                    data: JSON.stringify({
                      category: cat,
                      child: child
                    })
                  }
                });
                this.getcategories(child);
              }
            } else {
              try {
                this.api
                  .postmethod("user/child/media", {
                    sessionid: this.store.get_token(),
                    child: child,
                    appid: this.store.getuserdata().appid.S
                  })
                  .subscribe((res: any) => {
                    var tis = this;
                    if (res.status == "success" && res.code == 200) {
                      if (res.data.Items && res.data.Items.length) {
                        this.api.handle_Error({
                          message: "Got Media but work is in progress"
                        });
                        // if (category != "null" && child) {
                        //   this.router.navigate(["/mediamanagerchild"], {
                        //     queryParams: {
                        //       data: JSON.stringify({
                        //         category: [category],
                        //         child: child
                        //       })
                        //     }
                        //   });
                        // }
                      } else {
                        // this.api.handle_Error({
                        //   message:
                        //     "This category don't have subcategory and media"
                        // });
                        this.router.navigate(["/mediamange"]);
                      }
                    } else {
                      this.api.handle_Error(res);
                    }
                  });
              } catch (err) {
                this.api.handle_Error(err);
              }
              // this.api.handle_Error({ message: "Don't have sub Category" });
            }
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
  }

  addnew() {
    this.router.navigate(["/managecategories"]);
  }
  addnewmedia() {
    this.page = "addmedia";
  }
  medias: any;
  gotmedias() {
    console.log(this.medias);
  }
  uploadFile() {
//   debugger;
    try {
      this.api
        .postmethod("api/upload", {
          sessionid: this.store.get_token(),
          file: this.medias
        })
        .subscribe((res: any) => {
      //   debugger;
          var tis = this;
          if (res.status == "success" && res.code == 200) {
            this.categories = res.data.Items;
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
}
