import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { concat } from  'rxjs';

@Component({
  selector: "app-manage-media",
  templateUrl: "./manage-media.component.html",
  styleUrls: ["./manage-media.component.scss"]
})
export class ManageMediaComponent implements OnInit {
  submitted = false;
  mediaForm: FormGroup;
  public uploader: FileUploader = new FileUploader({})
  public hasBaseDropZoneOver: boolean = false

  constructor(
    private api: ApiService,
    private store: StorageService,
    private formBuilder: FormBuilder,
    private router: Router,    
  ) {
    this.get_app();
  }

  ngOnInit(): void {
    this.getcategories();
    this.mediaForm = this.formBuilder.group({
      title: ["", [Validators.required]],
      category: ["", [Validators.required]],
      description: [""],
      status: [""],
      date: [""]
    });
  }

  sub: any;
  get_app(){
    try {
     this.api
       .postmethod("app_cust/app", {
         sessionid: this.store.get_token(),
         appid: this.store.getuserdata().appid.S
       })
       .subscribe((res: any) => {
         if (res.status == "success" && res.code == 200) {
          this.sub = res.data;
         } else {
           this.api.handle_Error(res);
         }
       });
     } catch (err) {
       this.api.handle_Error(err);
    }
   };

  fileOverBase(event):  void {
    this.hasBaseDropZoneOver  =  event;
  }
  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }
  upload() {   
    let files = this.getFiles();
    console.log(files);
    let requests = [];
    files.forEach((file) => {
      let formData = new FormData();
      formData.append('file' , file.rawFile, file.name);
      //requests.push(this.uploadService.upload(formData));     
    });

    concat(...requests).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {  
        console.log(err);
      }
    );
  }

  categories: any;
  getcategories() {
    try {
      this.api
        .postmethod("user/all/category", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.categories = res.data.Items;
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  imageSrc: any;
  readURL(event: Event): void {
    var ss: any = event.target;
    if (ss.files && ss.files[0]) {
      const file = ss.files[0];

      const reader = new FileReader();
      reader.onload = e => (this.imageSrc = reader.result);

      reader.readAsDataURL(file);
    }
  }
  get f() {
    return this.mediaForm.controls;
  }
  submitPreview() {
    this.submitted = true;
    if (this.mediaForm.invalid) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return;
    }

    var ss: any = document.getElementById("media-sound-file");
    if (ss && ss.value) {
      var files: any = document.getElementById("media-sound-file");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload-recording", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            this.validrecord(dd.body.imageUrl);
          }
        });
    }
  }
  validrecord(recordingurl) {
    var appid;
    if (this.store.getuserdata().appid && this.store.getuserdata().appid.S) {
      appid = this.store.getuserdata().appid.S;
    }
    var ss: any = document.getElementById("upload-media-filem");
    if (ss && ss.value) {
      var files: any = document.getElementById("upload-media-filem");
      var file = files.files[0];
      if (file == null) {
        return alert("No file selected.");
      }
      this.api
        .uploadimage("image", file, "image-upload", this.sub.Lusername.S)
        .subscribe((event: HttpEvent<any>) => {
          var dd: any = event;
          if (dd && dd.body && dd.body.imageUrl) {
            this.submit(appid, dd.body.imageUrl);
          }
        });
    } else {
      this.submit(appid, "");
    }
  }
  submit(appid, imageurl) {
//   debugger;
    try {
      this.api
        .postmethod("user/create/media", {
          sessionid: this.store.get_token(),
          appid: appid,
          category: this.mediaForm.value.category,
          title: this.mediaForm.value.title,
          description: this.mediaForm.value.description,
          status: this.mediaForm.value.status,
          date_time: Date.now(),
          media: imageurl,
          recording: imageurl
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.router.navigate(["/listmedia"]);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  addMedia() {
    this.router.navigate(["/mediaupload"]);
  }
}
