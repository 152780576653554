import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";
import { retry, catchError } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { Observable, throwError } from "rxjs";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  private REST_API_SERVER = "https://api.shiur.cloud:5000/";
  // private REST_API_SERVER = "http://localhost:5000/";
  
  getapiurl(){
    return this.REST_API_SERVER
  }
  constructor(
    private routes: Router,
    private httpClient: HttpClient,
    private store: StorageService,
    private ngxService: NgxUiLoaderService
  ) {}
  popover(msg) {
    alert(msg);
  }
  login(data) {
    return this.httpClient
      .post(this.REST_API_SERVER + "user/login", data)
      .pipe(catchError(this.handleError));
  }
  logout() {
    this.store.remove_token();
    this.store.removeuserdata();
    this.routes.navigate(["/login"]);
  }
  register() {}

  /************GET***********/
  getmethod(url) {
    return this.httpClient
      .get(this.REST_API_SERVER + url)
      .pipe(catchError(this.handleError));
  }
  /************Post***********/
  postmethod(url, data) {
    return this.httpClient
      .post(this.REST_API_SERVER + url, data)
      .pipe(catchError(this.handleError));
  }
  uploadimage(name: string, profileImage: File, url, appid): Observable<any> {
    var formData: any = new FormData();
    formData.append("appid", appid);
    formData.append("name", name);
    formData.append("image", profileImage);
    return this.httpClient
      .post(this.REST_API_SERVER + url, formData, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(catchError(this.errorMgmt));
  }
  uploadpdf(name: string, profileImage: File, url, appid): Observable<any> {
    var formData: any = new FormData();
    formData.append("appid", appid);
    formData.append("name", name);
    formData.append("image", profileImage);

    return this.httpClient
      .post(this.REST_API_SERVER + url, formData, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(catchError(this.errorMgmt));
  }
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  handle_Error(res) {
    this.popover(res.message);
    if (res.code == 400) {
      // Server query error
    } else if (res.code == 401) {
      // Session Expired
      this.logout();
    } else if (res.code == 402) {
      // Something required
    } else if (res.code == 409) {
      // Conflict (Already Registered)
    }
  }

  // Pages Length
  getpageslength(data) {
    if (data > 0 && data < 11) {
      return [10];
    } else if (data > 10 && data < 26) {
      return [10, 25];
    } else if (data > 25 && data < 51) {
      return [10, 25, 50];
    } else if (data > 50 && data < 101) {
      return [10, 25, 50, 100];
    } else if (data > 100 && data < 151) {
      return [10, 25, 50, 100, 150];
    }
  }
  getpagelength(data) {
    if (data > 0 && data < 11) {
      return [1];
    } else if (data > 10 && data < 21) {
      return [1, 2];
    } else if (data > 20 && data < 31) {
      return [1, 2, 3];
    } else if (data > 30 && data < 41) {
      return [1, 2, 3, 4];
    } else if (data > 40 && data < 51) {
      return [1, 2, 3, 4, 5];
    } else if (data > 50 && data < 61) {
      return [1, 2, 3, 4, 5];
    } else if (data > 60 && data < 71) {
      return [1, 2, 3, 4, 5, 6];
    }
  }

   getlength(no, mu){
    return [(no-1)*mu, no*mu]
  }

  SecondsTohhmmss(totalSeconds) {
    var hours   = Math.floor(totalSeconds / 3600);
    var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    var seconds = totalSeconds - (hours * 3600) - (minutes * 60);
  
    // round seconds
    seconds = Math.round(seconds * 100) / 100
  
    var result = (hours < 10 ? "0" + hours : hours);
        result += ":" + (minutes < 10 ? "0" + minutes : minutes);
        result += ":" + (seconds  < 10 ? "0" + seconds : seconds);
    return result;
  }
}
