import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: 'app-contentanalytics',
  templateUrl: './contentanalytics.component.html',
  styleUrls: ['./contentanalytics.component.scss']
})
export class ContentanalyticsComponent implements OnInit {

  constructor( private api: ApiService,
    private store: StorageService,) { 
    // this.getanalytics()
  }

  ngOnInit(): void {
  }

  updatefilterfrom(){
    // // Create new Date instance
    // var date = new Date(this.filterfrom)
    // // Add a day
    // date.setDate(date.getDate() + 1)
    // var month 
    // if(date.getMonth()+1>9){
    //   month = date.getMonth()+1
    // }
    // else{
    //   month = "0"+(date.getMonth()+1)
    // }
    // var datee
    // if(date.getDate()>9){
    //   datee = date.getDate()
    // }
    // else{
    //   datee = "0"+(date.getDate())
    // }
    // this.filterto = date.getFullYear()+"-"+month+"-"+datee
  }

	goBack() {
		window.history.back();
	  }
  fetchingdata: boolean = false;
  filterfrom: any = new Date().toISOString().substr(0, 10);
  filterto: any = new Date().toISOString().substr(0, 10);
  groupby: any = "day";
  grouplist: any = [];
  filteranalytics(){
    if(new Date(this.filterto).getTime()<new Date(this.filterfrom).getTime()){
      this.api.popover("To date cannot be greater than from date")
      return 
    }
    // debugger
    if(this.filterfrom && this.filterto && this.groupby){
      if(this.groupby == 'day'){
        var y = 0
        this.grouplist = []
        this.grouplist.push("Name")
        for(var x = new Date(this.filterfrom).getTime(); x<=new Date(this.filterto).getTime() ; x){
          var date = new Date(this.filterfrom)
          this.grouplist.push(new Date(date.setDate(date.getDate() + y)).getTime())
          x = x+86400000
          y++
        }
        console.log(this.grouplist)
      }
      // this.getanalytics2()
      this.getanalytics()
    }
    else{
      this.api.popover("Please provide filter from date, filter to date and group by")
      return 
    }
  }

  analyticslist: any = []
  analyticslists: any = []
  searched : boolean = false

  getanalytics2(){
    if(this.fetchingdata){
      return
    }
    this.analyticslists = []
    this.fetchingdata = true
    setTimeout(()=>{
      this.fetchingdata = false
    }, 600000)
    try {
      this.api
        .postmethod("user/update/analytics2", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S,
          fromdate: new Date(this.filterfrom).getTime(),
          todate: new Date(this.filterto).getTime()
        })
        .subscribe((res: any) => {
          if(res.status == "success" && res.code == 200){
            if(res.data.Items && res.data.Items.length>0){
              try {
                this.api
                  .postmethod("user/update/analyticsusers", {
                    sessionid: this.store.get_token(),
                    appid: this.store.getuserdata().appid.S,
                    fromdate: new Date(this.filterfrom).getTime(),
                    todate: new Date(this.filterto).getTime()
                  })
                  .subscribe((resusers: any) => {
                    var tis = this
                    this.searched = true
                    this.fetchingdata = false
                    if(resusers.status == "success" && resusers.code == 200){
                      if(resusers.data.Count==0){
                        this.analyticslist = []
                      }
                      else{
                        console.log(res)
                        console.log(resusers)
                      }
                    }
                  },
                  error=>{
                    this.fetchingdata = false
                  });
                
              } catch (err) {
              }
            }
            else{
              this.analyticslist = []
            }
          }
        },
        error=>{
          this.fetchingdata = false
        });
      
    } catch (err) {
    }
  }


  getanalytics(){
    if(this.fetchingdata){
      return
    }
    this.analyticslists = []
    this.fetchingdata = true
    setTimeout(()=>{
      this.fetchingdata = false
    }, 600000)
    try {
      this.api
        .postmethod("user/update/analytics", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S,
          fromdate: new Date(this.filterfrom).getTime(),
          todate: new Date(this.filterto).getTime()
        })
        .subscribe((res: any) => {
          var tis = this
          this.searched = true
          this.fetchingdata = false
          if(res.status == "success" && res.code == 200){
            if(res.data.Count==0){
              this.analyticslist = []
            }
            else{
              this.analyticslist = res.data.Items
              this.analyticslist = this.analyticslist.sort(function(a: any, b: any){return parseInt(a.createdate.N)-parseInt(b.createdate.N)});


              // Insert Names
              for(var x = 0; x<this.analyticslist.length; x++){
                if(this.analyticslists.findIndex(e=>e.medialusername == this.analyticslist[x].medialusername.S && e.mediaxlusername == this.analyticslist[x].mediaxlusername.S)>-1){
                }
                else{
                  const items = this.analyticslist.filter(item => 
                    item.medialusername.S.indexOf(this.analyticslist[x].medialusername.S) !== -1 
                  && 
                  item.mediaxlusername.S.indexOf(this.analyticslist[x].mediaxlusername.S) !== -1);
                  var priceduration = 0
                  items.forEach((data, index)=>{
                    priceduration = priceduration + parseInt(data.top.S) - parseInt(data.fromp.S)
                  })
                  var fname = ""
                  var lname = ""
                  if(this.analyticslist[x].user[0] && this.analyticslist[x].user[0].firstname && this.analyticslist[x].user[0].firstname.S){
                    fname = this.analyticslist[x].user[0].firstname.S
                  }

                  if(this.analyticslist[x].user[0] && this.analyticslist[x].user[0].lastname && this.analyticslist[x].user[0].lastname.S){
                    lname = this.analyticslist[x].user[0].lastname.S
                  }

                  this.analyticslists.push({medialusername: this.analyticslist[x].medialusername.S, mediaxlusername: this.analyticslist[x].mediaxlusername.S,"username":fname+" "+lname,"medianame":this.analyticslist[x].name.S, grouplist: [], priceduration: priceduration})
                }
                if(this.analyticslist.length-1 == x){
                  this.analyticslists.sort(function(a: any, b: any){return parseInt(b.priceduration)-parseInt(a.priceduration)})
                  // this.analyticslists.sort(function(a: any, b: any){return parseInt(b.priceduration)-parseInt(a.priceduration)})
                }
                }
              }
          }
        },
        error=>{
          this.fetchingdata = false
          console.log(error)
        });
    } catch (err) {
      console.log(err)
      this.fetchingdata = false
      this.api.handle_Error("Not able to reorder due to the internet speed, Please reorder the file again to fix this position");
    }
  }

}
