import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: 'kt-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changeForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private store: StorageService,
    private router: Router,
    private route: ActivatedRoute) { }

  sub: any
  ngOnInit(): void {
    this.changeForm = this.formBuilder.group({
      opassword: ["", [Validators.required, Validators.minLength(6)]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      cpassword: ["", [Validators.required, Validators.minLength(6)]],
    },{validator: this.checkIfMatchingPasswords('password', 'cpassword')});
  }
  get f() {
    return this.changeForm.controls;
  }
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      }
      else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  submitPreview(){
    this.submitted = true;
    if (this.changeForm.invalid) {
      return;
    }
//   debugger
    try {
      this.api
        .postmethod("user/changepassword", {
          lusername: this.store.getuserdata().Lusername.S,
          xlusername: this.store.getuserdata().XLusername.S,
          opassword: this.changeForm.value.opassword,
          password: this.changeForm.value.password,
          timestamp: Date.now(),
          created: Date.now(),
          sessionid: this.store.get_token()
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.api.popover(res.message);
            this.api.logout()
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  goBack() {
    window.history.back();
  }
}
