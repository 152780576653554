import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-list-media",
  templateUrl: "./list-media.component.html",
  styleUrls: ["./list-media.component.scss"]
})
export class ListMediaComponent implements OnInit {
  constructor(
    private api: ApiService,
    private store: StorageService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getmedias();
    this.getcategories();
  }
  pagenumbers: any;
  pagenumber: any;
  medias: any = [];
  getmedias() {
    try {
      this.api
        .postmethod("user/all/media", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
          if (res.status == "success" && res.code == 200) {
            this.medias = res.data.Items;
            this.pagenumbers = [];
            this.pagenumber = [];
            this.pagenumbers = this.api.getpageslength(this.medias.length);
            this.pagenumber = this.api.getpagelength(this.medias.length);
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  addMeadia() {
    this.router.navigate(["/managemedia"]);
  }
  editList(data) {
    this.router.navigate(["/editlistmedia"], {
      queryParams: {
        data: JSON.stringify(data)
      }
    });
  }
  categories: any;
  getcategories() {
    try {
      this.api
        .postmethod("user/all/category", {
          sessionid: this.store.get_token(),
          appid: this.store.getuserdata().appid.S
        })
        .subscribe((res: any) => {
      //   debugger;
          if (res.status == "success" && res.code == 200) {
            this.categories = res.data.Items;
          } else {
            this.api.handle_Error(res);
          }
        });
    } catch (err) {
      this.api.handle_Error(err);
    }
  }
  viewdetail() {}
}
