import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { StorageService } from "../../services/storage.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";

@Component({
  selector: "app-edit-category",
  templateUrl: "./edit-category.component.html",
  styleUrls: ["./edit-category.component.scss"]
})
export class EditCategoryComponent implements OnInit {
  sub: any;
  categoryForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private store: StorageService
  ) {}

  ngOnInit(): void {
    // this.getcategories();
    // var query: any = this.route.queryParams;
    // console.log(JSON.parse(query.value.data).parent);
    // this.sub = JSON.parse(query.value.data).parent;
    // this.categoryForm = this.formBuilder.group({
    //   category: [this.sub.category.S, [Validators.required]],
    //   parentcategory: [this.sub.parentcategory.S],
    //   image: [this.sub.media.S],
    //   description: [this.sub.description.S],
    //   categoryposition: [this.sub.categoryposition.N],
    //   categorystatus: [this.sub.categorystatus.S]
    // });
    // if (this.sub.media && this.sub.media.S && this.sub.media.S != "null") {
    //   this.imageSrc = this.sub.media.S;
    // } else {
    //   this.imageSrc = "";
    // }
  }

  // imageSrc: any;
  // readURL(event: Event): void {
  //   var ss: any = event.target;
  //   if (ss.files && ss.files[0]) {
  //     const file = ss.files[0];

  //     const reader = new FileReader();
  //     reader.onload = e => (this.imageSrc = reader.result);

  //     reader.readAsDataURL(file);
  //   }
  // }
  // get f() {
  //   return this.categoryForm.controls;
  // }
  // categories: any;
  // getcategories() {
  //   try {
  //     this.api
  //       .postmethod("user/all/category", {
  //         sessionid: this.store.get_token()
  //       })
  //       .subscribe((res: any) => {
  //         if (res.status == "success" && res.code == 200) {
  //           this.categories = res.data.Items;
  //         } else {
  //           this.api.handle_Error(res);
  //         }
  //       });
  //   } catch (err) {
  //     this.api.handle_Error(err);
  //   }
  // }
  // submitPreview() {
  //   this.submitted = true;
  //   if (this.categoryForm.invalid) {
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //       behavior: "smooth"
  //     });
  //     return;
  //   }
  //   var appid;
  //   if (this.store.getuserdata().appid && this.store.getuserdata().appid.S) {
  //     appid = this.store.getuserdata().appid.S;
  //   }
  //   var ss: any = document.getElementById("upload-media-file1");
  //   if (ss && ss.value) {
  //     var files: any = document.getElementById("upload-media-file1");
  //     var file = files.files[0];
  //     if (file == null) {
  //       return alert("No file selected.");
  //     }
  //     this.api
  //       .uploadimage("image", file, "image-upload")
  //       .subscribe((event: HttpEvent<any>) => {
  //         console.log(event);
  //         var dd: any = event;
  //         if (dd && dd.body && dd.body.imageUrl) {
  //       //   debugger;
  //           this.submit(appid, dd.body.imageUrl);
  //         }
  //       });
  //   } else {
  //     this.submit(appid, this.imageSrc);
  //   }
  // }

  aresetPreview(){
    this.router.navigate(['/allfolder'])
  }

  // submit(appid, imageurl) {
  //   try {
  //     this.api
  //       .postmethod("user/edit/category", {
  //         sessionid: this.store.get_token(),
  //         appid: this.sub.appid.S,
  //         category: this.categoryForm.value.category,
  //         parentcategory: this.categoryForm.value.parentcategory,
  //         categorystatus: this.categoryForm.value.categorystatus,
  //         categoryposition: this.categoryForm.value.categoryposition,
  //         description: this.categoryForm.value.description,
  //         medias: imageurl,
  //         catid: this.sub.catid.S
  //       })
  //       .subscribe((res: any) => {
  //         if (res.status == "success" && res.code == 200) {
  //           this.api.popover(res.message);
  //           this.router.navigate(["/listcategory"]);
  //         } else {
  //           this.api.handle_Error(res);
  //         }
  //       });
  //   } catch (err) {
  //     this.api.handle_Error(err);
  //   }
  // }
  // resetPreview() {
  //   this.router.navigate(["listcategory"]);
  // }

  ngSubmit(){
    this.router.navigate(['/allfolder'])
  }
}
