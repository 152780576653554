import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { AppComponent } from "./app.component";
// Import containers
import { DefaultLayoutComponent } from "./containers";
//import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { OrderModule } from 'ngx-order-pipe';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { PdfViewerModule } from 'ng2-pdf-viewer';

const APP_CONTAINERS = [DefaultLayoutComponent];
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule
} from "@coreui/angular";
// Import routing module
import { AppRoutingModule } from "./app.routing";
// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ChartsModule } from "ng2-charts";
import { NgbTabsetModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { AccMagaComponent } from "./views/acc-maga/acc-maga.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ManageCategoriesComponent } from "./views/manage-categories/manage-categories.component";
import { ListCategoriesComponent } from "./views/list-categories/list-categories.component";
import { EditCategoryComponent } from "./views/edit-category/edit-category.component";
import { SubCategoryComponent } from "./views/sub-category/sub-category.component";
import { EditSubComponent } from "./views/edit-sub/edit-sub.component";
import { ManageMediaComponent } from "./views/manage-media/manage-media.component";
import { ListMediaComponent } from "./views/list-media/list-media.component";
import { ViewStatisticsComponent } from "./views/view-statistics/view-statistics.component";
import { ManageUsersComponent } from "./views/manage-users/manage-users.component";
import { ListUsersComponent } from "./views/list-users/list-users.component";
import { AccessHistoryComponent } from "./views/access-history/access-history.component";
import { AppStatisticsComponent } from "./views/app-statistics/app-statistics.component";
import { EditListMediaComponent } from "./views/edit-list-media/edit-list-media.component";
import { AndroidAppComponent } from "./views/android-app/android-app.component";
import { IosAppComponent } from "./views/ios-app/ios-app.component";
import { FavoritesMediaComponent } from "./views/favorites-media/favorites-media.component";
import { DownloadedMediaComponent } from "./views/downloaded-media/downloaded-media.component";
import { NotificationComponent } from "./views/notification/notification.component";
import { ChangePasswordComponent } from "./views/change-password/change-password.component";
import { UiSwitchModule } from "ngx-toggle-switch";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UploadFileComponent } from "./views/upload-file/upload-file.component";
import { MediaManagerComponent } from "./views/media-manager/media-manager.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MediaManagerChildComponent } from "./views/media-manager-child/media-manager-child.component";
import { MediaManageComponent } from "./views/media-manage/media-manage.component";
import { FileUploadModule } from "ng2-file-upload";
import { AddFolderComponent } from "./views/add-folder/add-folder.component";
import { AllFolderComponent } from "./views/all-folder/all-folder.component";
import { AddMediaComponent } from "./views/add-media/add-media.component";
import { EditMediaComponent } from "./views/edit-media/edit-media.component";
import { EditListComponent } from "./views/edit-list/edit-list.component";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoaderComponent } from "./services/loader.component";
import { LoaderService } from "./services/loader.service";
import { OrderBynumberPipe } from './pipes/order-bynumber.pipe';
import { FilternamePipe } from './pipes/filtername.pipe';
import { MatVideoModule } from 'mat-video';
import { AppCustomizationComponent } from './views/app-customization/app-customization.component';
import { FilterallnotificationsPipe } from './pipes/filterallnotifications.pipe';
import {ProgressBarModule} from "angular-progress-bar";
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { NgpSortModule } from "ngp-sort-pipe";

import { ContentanalyticsComponent } from './views/contentanalytics/contentanalytics.component';
import { UserpipePipe } from './pipes/userpipe.pipe';
import { ShareComponent } from './views/share/share.component';
import {MatExpansionModule} from '@angular/material/expansion';

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("624796833023-clhjgupm0pu6vgga7k5i5bsfp6qp6egh.apps.googleusercontent.com")
  }
]);
export function provideConfig() {
  return config;
}

@NgModule({
  imports: [
    NgpSortModule,
    PdfViewerModule,
    Ng2ImgMaxModule,
    MatVideoModule,
    OrderModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UiSwitchModule,
    NgbModule,
    NgxUiLoaderModule,
    FileUploadModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgbTabsetModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DragDropModule,
    NgxAudioPlayerModule,
    ProgressBarModule,
    SocialLoginModule,
    MatExpansionModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoaderComponent,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    AccMagaComponent,
    ConfirmDialogComponent,
    ManageCategoriesComponent,
    ListCategoriesComponent,
    EditCategoryComponent,
    SubCategoryComponent,
    EditSubComponent,
    ChangePasswordComponent,
    ManageMediaComponent,
    ListMediaComponent,
    ViewStatisticsComponent,
    ManageUsersComponent,
    ListUsersComponent,
    AccessHistoryComponent,
    AppStatisticsComponent,
    EditListMediaComponent,
    AndroidAppComponent,
    IosAppComponent,
    FavoritesMediaComponent,
    DownloadedMediaComponent,
    NotificationComponent,
    UploadFileComponent,
    MediaManagerComponent,
    MediaManagerChildComponent,
    MediaManageComponent,
    AddFolderComponent,
    AllFolderComponent,
    AddMediaComponent,
    EditMediaComponent,
    EditListComponent,
    OrderBynumberPipe,
    FilternamePipe,
    AppCustomizationComponent,
    FilterallnotificationsPipe,
    DashboardComponent,
    ContentanalyticsComponent,
    UserpipePipe,
    ShareComponent,
  ],
  exports: [AccessHistoryComponent],

  providers: [
    LoaderService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmDialogComponent]
})
export class AppModule {}
