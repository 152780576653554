import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class StorageService {
  is_login: boolean;

  constructor(private routes: Router) {}

  // Token

  have_token() {
    if (localStorage.getItem("lect_token") && this.getuserdata()) {
      return true;
    } else {
      return false;
    }
  }

  set_token(token) {
    localStorage.setItem("lect_token", btoa(token));
  }

  get_token() {
    if (this.have_token()) {
      if(localStorage.getItem("lect_token")){
        try{
          return atob(localStorage.getItem("lect_token"));
        }
        catch(err){
          this.removeuserdata();
          return this.routes.navigate(["/login"]);
        }
      }
      else{
        return ""
      }
    } else {
      this.removeuserdata();
      return this.routes.navigate(["/login"]);
    }
  }

  remove_token() {
    if (localStorage.getItem("lect_token")) {
      localStorage.removeItem("lect_token");
    }
  }

  user_type() {
    return "admin";
  }

  // User Data

  setuserdata(data) {
    localStorage.setItem("userlocaldata", JSON.stringify(data));
  }

  getuserdata() {
    if (localStorage.getItem("userlocaldata")) {
      return JSON.parse(localStorage.getItem("userlocaldata"));
    }
  }

  removeuserdata() {
    if (localStorage.getItem("userlocaldata")) {
      localStorage.removeItem("userlocaldata");
    }
  }

  // Set auth login

  setgoogletoken(token){
    localStorage.setItem("googletoken", JSON.stringify(token));
  }

  getgoogletoken() {
    if (localStorage.getItem("googletoken")) {
      return JSON.parse(localStorage.getItem("googletoken"));
    }
  }

  removegoogletoken() {
    if (localStorage.getItem("googletoken")) {
      localStorage.removeItem("googletoken");
    }
  }
}
